/* eslint-disable no-param-reassign */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Row from 'antd/lib/row';
import { get } from 'lodash';
import Spin from 'antd/lib/spin';
import Typography from 'antd/lib/typography';
import Title from 'antd/lib/typography/Title';
import { Button, Card, Space, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import './styles.less';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';
import { DebtRecoveryCreateForm } from './Create';
import { notificationSuccess } from '../../utils/Notification';
import { convertVND } from '../../utils/convert';

const DebtRecovery = () => {
  const loading = useSelector(state => {
    return get(state, 'common.loading', false);
  });

  const list = useSelector(state => state?.debtRecovery?.list || []);

  useEffect(() => {
    dispatch(actions.getListDebtRecovery());
  }, []);

  useEffect(() => {
    return () => dispatch(actions.clearCustomer());
  }, []);

  const columnProps = {
    align: 'left',
    ellipsis: true,
    width: 200
  };

  const columns = [
    {
      ...columnProps,
      fixed: 'left',
      title: 'Ngày tạo',
      width: 90,
      dataIndex: 'created_at',
      render: date => (
        <Typography>{moment(date).format('YYYY-MM-DD HH:MM:SS')}</Typography>
      )
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Khách hàng',
      width: 90,
      dataIndex: 'user',
      render: user => <Tooltip title={user.email}>{user.name}</Tooltip>
    },
    {
      ...columnProps,
      // fixed: 'right',
      align: 'right',
      title: 'Số tiền',
      width: 60,
      dataIndex: 'amount',
      render: amount => <>{convertVND(amount)}</>
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Người tạo',
      width: 90,
      dataIndex: 'created_by',
      render: admin => <Typography>{admin.name}</Typography>
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: status => {
        if (status === 'wating') {
          return <Tag color="yellow">Chờ chuyển tiền</Tag>;
        }
        if (status === 'success') {
          return <Tag color="green">Đã chuyển tiền</Tag>;
        }
        return <Tag color="orange">Đã đóng</Tag>;
      }
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, record) => {
        if (record?.status === 'wating') {
          return (
            <Space>
              <Button
                type="dashed"
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `${process.env.REACT_APP_HOST_WEB_APP}/debt-recovery/${record?._id}`
                    )
                    .then(() => notificationSuccess('Copy thành công'));
                }}
              >
                Nhận Link
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(actions.closeDebtRecovery(record._id))}
              >
                Đóng
              </Button>
            </Space>
          );
        }
        return null;
      }
    }
  ];

  return (
    <div className="create-sale-order">
      <Spin spinning={loading}>
        <Row justify="space-between" align="top">
          <Title className="title" level={5}>
            Thu hồi công nợ
          </Title>
        </Row>
        <>
          <Card className="card" title="Danh sách">
            <Table
              bordered
              size="small"
              dataSource={list}
              scroll={{ x: '100%' }}
              columns={columns}
              rowClassName="editable-row"
              pagination={{
                pageSize: 10,
                size: 'small'
              }}
            />
          </Card>

          <Card className="card" title="Tạo mới">
            <DebtRecoveryCreateForm />
          </Card>
        </>
      </Spin>
    </div>
  );
};

export default DebtRecovery;
