export const CompanyName = 'BuyNgon';

export const MessageContent = {
  ChangeStatusSuccess: 'Thay đổi trạng thái thành công',
  ChangeStatusFailed: 'Thay đổi trạng thái thất bại',
  ConnectServerSuccess: 'Kết nối với Server thành công',
  ConnectServerFailed: 'Kết nối với Server thất bại',
  LoginTokenExpired: 'Token đăng nhập đã hết hạn',
  LoginSuccess: 'Đăng nhập thành công',
  LoginFailed: 'Tài khoản không hợp lệ',
  ReloadAndLoginAgain: 'Vui lòng tải lại trang và đăng nhập lại',
  LoginTitle: `Đăng nhập với ${CompanyName}`,
  Login: 'Đăng nhập',
  Logout: 'Đăng xuất',
  ForgotPassword: 'Quên mật khẩu?',
  PleaseInputUsername: 'Vui lòng điền tên đăng nhập',
  PleaseInputPassword: 'Vui lòng điền mật khẩu',
  InputUsername: 'Nhập tên đăng nhập',
  InputPassword: 'Nhập mật khẩu',
  Status: 'Trạng thái',
  Name: 'Tên',
  Email: 'Email',
  Phone: 'Số điện thoại',
  Balance: 'Số dư',
  Provider: 'Nguồn',
  Address: 'Địa chỉ',
  Role: 'Quyền hạn',
  OdooCustomerID: 'Odoo ID',
  ChangeStatusCustomer: 'Bạn có muốn thay đổi trạng thái khách hàng này?',
  ChangeStatusAccount: 'Bạn có muốn thay đổi trạng thái tài khoản này?',
  CustomerInfo: 'Thông tin khách hàng',
  CustomerName: 'Tên Khách hàng',
  AccountInfo: 'Thông tin tài khoản',
  TransactionInfo: 'PAYMENT - THÔNG TIN THANH TOÁN',
  DealTransactionInfo: 'DEAL - THÔNG TIN THANH TOÁN',
  DealManage: 'DEAL - THÔNG TIN DEAL',
  DealWithdrawInfo: 'DEAL - HOÀN TRẢ DEAL',
  Wallet: 'Dư nợ',
  WalletDetail: 'Chi tiết dư nợ',
  AddressDelivery: 'Địa chỉ nhận hàng của khách hàng',
  DetailInfo: 'Thông tin chi tiết',
  SearchBy: 'Tìm kiếm theo...',
  SearchByMail: 'Tìm kiếm theo email',
  Category: 'Danh mục',
  OutLayoutTitle: `Vui lòng đăng nhập để truy cập hệ thống Quản lý của ${CompanyName}`,
  OutLayoutDescribe:
    'Trong trường hợp không thể truy cập được hoặc gặp sự cố, vui lòng liên hệ Admin để được hỗ trợ qua hotline hoặc email!',
  YesContent: 'Có',
  NoContent: 'Không',
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  BalanceDescending: 'Số dư giảm dần',
  BalanceAscending: 'Số dư tăng dần',
  Confirm: 'Xác nhận',
  Detail: 'Chi tiết',
  SaleOrderDetail: 'Chi tiết đơn hàng',
  UndefinedPrice: 'Không xác định số tiền',
  Accept: 'OK',
  Cancel: 'Hủy',
  Clear: 'Xóa',
  Filter: 'Lọc',
  Action: 'Hành động',
  Reject: 'Huỷ đơn',
  PaidConfirm: 'Xác nhận thanh toán',
  Close: 'Đóng lại',
  PaymentCode: 'Mã nạp',
  PaymentDate: 'Ngày giao dịch',
  Bank: 'Ngân hàng',
  ProductCost: 'Tiền hàng',
  ServiceFee: 'Tiền phí dịch vụ',
  PaymentCost: 'Số tiền đề xuất',
  PriceTranfer: 'Số tiền thực nhận',
  PaymentInfo: 'Thông tin giao dịch',
  PaymentInfoMemo: 'Thông tin giao dịch (memo)',
  PaymentReference: 'Payment reference',
  NotificationUpdateTimeTransaction:
    'Thời gian giao dịch của bạn đang được cập nhật lại. Vui lòng chờ trong giây lát',
  PaidInfoError: 'Chưa có thông tin giao dịch, vui lòng nhập lại!',
  ConfirmUpdateCustomer: 'Xác nhận cập nhât thông tin khách hàng',
  DeliveryAddress: 'Địa chỉ nhận hàng',
  ReceiverName: 'Tên người nhận',
  DeleteAddress: 'Xóa địa chỉ này?',
  AddAddress: 'Thêm địa chỉ',
  ReloadPage: 'Tải lại trang',
  UserInterface: 'Giao diện',
  NewPassword: 'Nhập mật khẩu mới',
  CancelDate: 'Ngày huỷ',
  Message: 'Nội dung',
  Domain: 'Domain',
  Editor: 'Người xác nhận',
  InputInforPlease: 'Vui lòng nhập đầy đủ thông tin',
  CreatePayment: 'Tạo payment',
  OffPayment: 'Đóng payment',
  CsVerifySO: 'Kiểm tra',
  OTP: 'OTP',
  CreatedAt: 'Ngày tạo',
  WithdrawalRequest: 'Xử lí yêu cầu rút tiền',
  WithdrawalDetail: 'Chi tiết yêu cầu rút tiền',
  ExchangePointRequest: 'Xử lí yêu cầu đổi điểm thưởng',
  SellerProfile: 'Đối tác bán hàng (seller)',
  Edit: 'Chỉnh sửa',
  NameShop: 'Tên Shop',
  EmailShop: 'Email đăng kí',
  PhoneShop: 'Số điện thoại đăng kí',
  BuyNgonShop: 'Cửa hàng BuyNgon',
  ConfirmForCustomer: 'Xác nhận giùm khách',
  RejectForCustomer: 'Hủy giùm khách',
  Type: 'Loại',
  Campaign: 'Chiến dịch khuyến mãi'
};
