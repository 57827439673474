import { Button, Card, Input, Spin } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import UploadImage from '../UploadImage';

export default function FlashSale() {
  const [loading, setLoading] = useState(false);
  const flashSaleBanner = useSelector(state => {
    return get(state, 'configuration.banner.flashSale');
  });
  const [bg, setBg] = useState(flashSaleBanner?.bg || '#000000');

  const [file, handleUploadFile, handleReset] = useUploadFile(flashSaleBanner?.url);
  const handleSave = () => {
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: {
          flashSale: {
            type: file?.type || flashSaleBanner?.type,
            url: file?.link,
            bg
          }
        },
        callback: () => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
    );
  };

  // useEffect(() => {
  //   if (file.isFetching === false && file.link && file.type) {
  //     handleSave(file.type, file.link);
  //   }
  // }, [file.isFetching, file.link, file.type]);

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <div />
          <Button type="primary" onClick={handleSave}>
            <b>Lưu </b>
          </Button>
        </div>

        <div className="dealngon-banner">
          <Card
            title=" Banner Flash Sale"
            className="card"
            style={{
              width: '100%'
            }}
          >
            <div>
              <UploadImage
                isPreventCrop
                imgUrl={file.link}
                loading={file.isFetching}
                beforeUpload={handleUploadFile}
                handleReset={handleReset}
              />
              <Input
                style={{
                  width: 300,
                  padding: '5px 10px',
                  margin: 0,
                  marginTop: 20
                }}
                placeholder="Input background banner color"
                value={bg}
                onChange={e => setBg(e.target.value)}
              />
            </div>
          </Card>
        </div>
      </div>
    </Spin>
  );
}
