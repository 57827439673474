import { Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ExtensionTheme from '../../components/ExtensionTheme';
import ContentTheme from '../../components/theme/ContentTheme';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import './style.less';

const { Title } = Typography;

const TABS = [
  // { key: 'color', tab: 'Màu sắc', Component: ColorTheme },
  // { key: 'images', tab: 'Hình ảnh', Component: ImageTheme },
  { key: 'content', tab: 'Nội dung', Component: ContentTheme },
  { key: 'extension', tab: 'Extension Theme', Component: ExtensionTheme }
];

const Theme = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(TABS[0].key);

  useEffect(() => {
    dispatch(actions.getConfiguration());
    return () => dispatch(actions.clearConfiguration());
  }, [dispatch]);
  return (
    <div className="container">
      <Title className="title" level={5}>
        {MessageContent.UserInterface}
      </Title>
      <Tabs onChange={setTab}>
        <>
          {TABS.map(tabItem => {
            return (
              <Tabs.TabPane
                active={tabItem.key === tab}
                tab={tabItem.tab}
                key={tabItem.key}
              >
                <tabItem.Component />
              </Tabs.TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
};
export default Theme;
