import { Divider, InputNumber } from 'antd';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal/Modal';
import Spin from 'antd/lib/spin';
import Typography from 'antd/lib/typography';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { number } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BankList } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import currency from '../../utils/currency';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import UploadImage from '../UploadImage';
import './style.less';

const {
  PaymentCode,
  PaymentReference,
  PaymentDate,
  Bank,
  PaymentCost,
  PaymentInfoMemo,
  SaleOrderDetail,
  PaidConfirm,
  Reject,
  Close,
  PriceTranfer,
  InputInforPlease,
  CreatePayment,
  OffPayment
} = MessageContent;

const { Text } = Typography;

const ConfirmTransaction = () => {
  const [paidInfo, setPaidInfo] = useState('');
  const [priceTranfer, setPriceTranfer] = useState(null);
  const saleOrder = useSelector(state => {
    return get(state, 'transaction.detail', null);
  });
  const showType = useSelector(state => get(state, 'transaction.showType', null));
  const isShowConfirm = showType === 'confirm';
  const isLoading = useSelector(state => {
    return get(state, 'transaction.confirm.isLoading', false);
  });
  const [paymentRef, setPaymentRef] = useState('');
  const [onPayment, setOnPayment] = useState(false);
  const [form] = Form.useForm();

  const [file, handleUpload, handleReset, setFile] = useUploadFile();

  const onConfirm = () => {
    const { _id: saleOrderId, user_id: customerId } = saleOrder;
    const { link: paymentImg } = file;
    const isNumeric = val => {
      return /^-?\d+$/.test(val);
    };

    if (
      onPayment &&
      (!isNumeric(priceTranfer) || (isEmpty(paymentImg) && isEmpty(paymentRef)))
    ) {
      notificationError(InputInforPlease);
      return;
    }

    dispatch(
      actions.acceptTransaction(
        customerId,
        saleOrderId,
        paymentImg,
        paymentRef,
        priceTranfer,
        paidInfo
      ),
      false
    );
    setFile({ link: '', isFetching: false });
    setPaidInfo('');
    setPriceTranfer('');
    setPaymentRef('');
  };

  const onReject = () => {
    dispatch(actions.rejectTransaction(saleOrder), false);
  };

  const onCancel = () => {
    dispatch(actions.clearTransactionInfo(), false);
    setFile({ link: '', isFetching: false });
    setPaidInfo('');
    setPriceTranfer('');
    setPaymentRef('');
  };

  const onClickPayment = () => {
    setOnPayment(!onPayment);
    setFile({ link: '', isFetching: false });
    setPaidInfo('');
    setPriceTranfer('');
    setPaymentRef('');
  };

  if (!saleOrder) {
    return null;
  }

  const {
    payment_code: code,
    created_at: time,
    total_price_after_sale_VND: total
  } = saleOrder;
  const paidCode = <Text mark>{code}</Text>;
  const extrangeTime = moment(time).format('DD-MM-YYYY HH:mm:ss');
  const bank = BankList[code.split('-')[1]] || 'Không xác định';
  const totalCost = <Text type="danger">{`${currency.format(total)} đ`}</Text>;

  const handleChangePaymentRef = ({ target }) => {
    setPaymentRef(target.value);
  };

  const onChangePriceTranfer = val => {
    setPriceTranfer(val);
  };

  const formPayment = [
    {
      label: PriceTranfer,
      value: priceTranfer,
      inputNumber: 1,
      disabled: false,
      type: number,
      inputProps: {
        onChange: event => {
          return onChangePriceTranfer(event);
        }
      }
    },
    {
      label: PaymentInfoMemo,
      value: paidInfo,
      disabled: false,
      inputProps: {
        onChange: event => {
          return setPaidInfo(event.target.value);
        }
      }
    }
  ];

  const formInfo = [
    { label: PaymentCode, value: paidCode, disabled: true },
    { label: PaymentDate, value: extrangeTime, disabled: true },
    { label: Bank, value: bank, disabled: true },
    { label: PaymentCost, value: totalCost, disabled: true }
  ];

  const actionButtons = [
    <Button
      key="pm"
      style={{ float: 'left' }}
      type="primary"
      disabled={isLoading}
      onClick={() => {
        return onClickPayment();
      }}
    >
      {onPayment ? OffPayment : CreatePayment}
    </Button>,
    <Button key="rj" disabled={isLoading} danger onClick={onReject}>
      {Reject}
    </Button>,
    <Button key="cf" disabled={isLoading} type="primary" onClick={onConfirm}>
      {PaidConfirm}
    </Button>,
    <Button key="cl" disabled={isLoading} onClick={onCancel}>
      {Close}
    </Button>
  ];

  return (
    <Modal
      visible={!!saleOrder && isShowConfirm}
      title={SaleOrderDetail}
      onCancel={onCancel}
      footer={actionButtons}
      width={650}
    >
      <Spin spinning={isLoading}>
        <Form form={form} labelCol={{ span: 8 }}>
          {formInfo.map(item => (
            <Form.Item key={item.label} label={<Text strong>{item.label}</Text>}>
              {item.disabled ? (
                <p style={{ margin: 0 }}>{item.value}</p>
              ) : (
                <>
                  <Input value={item.value} {...item.inputProps} />
                </>
              )}
            </Form.Item>
          ))}

          <div className={`payment ${!onPayment ? 'off' : ''}`}>
            <Divider orientation="left" dash>
              <Text className="line">Payment</Text>
            </Divider>
            {formPayment.map(item => {
              return (
                <Form.Item key={item.label} label={<Text strong>{item.label}</Text>}>
                  {item.disabled ? (
                    <p style={{ margin: 0 }}>{item.value}</p>
                  ) : (
                    <>
                      {item.inputNumber ? (
                        <InputNumber
                          min={0}
                          value={item.value}
                          formatter={value => {
                            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          }}
                          {...item.inputProps}
                        />
                      ) : (
                        <Input value={item.value} {...item.inputProps} />
                      )}
                    </>
                  )}
                </Form.Item>
              );
            })}
            <Form.Item label={<Text strong>Ảnh/Reference:</Text>}>
              <UploadImage
                isPreventCrop
                isNew
                loading={file.isFetching}
                imgUrl={file.link}
                beforeUpload={handleUpload}
                handleReset={handleReset}
              />
              <Input
                style={{ marginTop: 16 }}
                value={paymentRef}
                onChange={handleChangePaymentRef}
                placeholder={PaymentReference}
              />
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ConfirmTransaction;
