import get from 'lodash/get';
import TYPE from '../actions/TYPE';

const initialState = {
  list: {
    count: 0,
    transactions: []
  },
  editQuotation: null,
  notification: {
    isLoading: false,
    saleOrderId: ''
  },
  detail: null,
  confirm: {
    isLoading: false
  },
  csVerifyRetail: {
    isLoading: false
  },
  isLoading: false,
  draftSaleOrder: [],
  listQuotationImport: [],
  showType: null
};

const transaction = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.HANDLE_EDIT_SUCCESS:
      return {
        ...state,
        draftSaleOrder: action.data
      };

    case TYPE.HANDLE_EDIT_QUOTATION:
      return {
        ...state,
        editQuotation: state.draftSaleOrder.quotation_list.find(
          item => item._id === action.payload
        )
      };

    case TYPE.HANDLE_HIDE_QUOTATION:
      return {
        ...state,
        editQuotation: null
      };

    case TYPE.LIST_DEAL_GIFT_CARD_TRANSACTION_SUCCESS:
      return {
        ...state,
        list: {
          count: state.list.count + action.data.count,
          transactions: [...action.data.results, ...state.list.transactions]
        }
      };

    case TYPE.IMPORT_QUOTATION_SUCCESS:
      return {
        ...state,
        listQuotationImport: action.data
      };

    case TYPE.CLEAR_CUSTOMER:
    case TYPE.CONFIRM_SALE_ORDER_SUCCESS:
      return {
        ...state,
        draftSaleOrder: []
      };

    case TYPE.CLEAR_IMPORT_QUOTATION:
      return {
        ...state,
        listQuotationImport: []
      };

    case TYPE.ADD_QUOTATION_TO_SALE_ORDER_SUCCESS:
    case TYPE.EDIT_QUOTATION_SUCCESS:
    case TYPE.DELETE_QUOTATION_SUCCESS:
    case TYPE.GET_DRAFT_SALE_ORDER_SUCCESS:
      return {
        ...state,
        draftSaleOrder: action.data
      };

    case TYPE.LIST_TRANSACTIONS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPE.LIST_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: {
          count: action.count,
          transactions: action.data
        }
      };
    }
    case TYPE.LIST_DEPOSITS_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          transactions: action.data
        }
      };
    }

    case TYPE.NOTIFICATION_TRANSACTION_SUCCESS: {
      return {
        ...state,
        notification: {
          isLoading: !state.notification.isLoading,
          saleOrderId: action.saleOrderId
        }
      };
    }
    case TYPE.GET_TRANSACTION_INFO:
      return {
        ...state,
        showType: 'confirm',
        detail: get(action, 'payload', null)
      };

    case TYPE.DETAIL_TRANSACTION:
      return {
        ...state,
        showType: 'detail',
        detail: get(action, 'params', null)
      };
    case TYPE.CLEAR_TRANSACTION_INFO:
      return { ...state, detail: null };
    case TYPE.ACCEPT_TRANSACTION:
    case TYPE.REJECT_TRANSACTION:
      return {
        ...state,
        confirm: {
          isLoading: true
        }
      };
    case TYPE.ACCEPT_TRANSACTION_FAILED:
    case TYPE.REJECT_TRANSACTION_FAILED:
      return {
        ...state,
        confirm: {
          isLoading: false
        }
      };
    case TYPE.ACCEPT_TRANSACTION_SUCCESS:
    case TYPE.REJECT_TRANSACTION_SUCCESS:
      const newList = state.list.transactions.filter(item => {
        return item._id !== state.detail._id;
      });
      return {
        ...state,
        detail: null,
        list: {
          count: state.list.count - 1,
          transactions: newList
        },
        confirm: {
          isLoading: false
        }
      };
    case TYPE.CS_VERIFY_RETAIL_SALE_ORDER:
      return {
        ...state,
        csVerifyRetail: {
          isLoading: !state.csVerifyRetail.isLoading
        }
      };
    case TYPE.CS_VERIFY_RETAIL_SALE_ORDER_SUCCESS:
      const newTransaction = state.list.transactions.filter(item => {
        return item._id !== state.detail._id;
      });

      return {
        ...state,
        detail: null,
        list: {
          count: state.list.count - 1,
          transactions: newTransaction
        },
        csVerifyRetail: {
          isLoading: !state.csVerifyRetail.isLoading
        }
      };

    case TYPE.UPDATE_WAITING_SALE_ORDER_SUCCESS:
      return {
        ...state,
        detail: null,
        list: {
          count: state.list.count,
          transactions: (() => {
            if (action.payload.type === 'reject') {
              return state.list.transactions.filter(
                item => item?._id !== action.payload.id
              );
            }
            if (action.payload.type === 'approve') {
              return state.list.transactions.map(item => {
                if (item?._id !== action.payload.id) return item;
                return { ...item, status: 'customer_confirmed' };
              });
            }
            return [...state.list.transactions];
          })()
          // [...state.list.transactions].reduce((r, quote) => {
          //   if (action.payload.type === 'reject') return r;
          //   if (quote?._id === action.payload.id) {
          //     return r.concat({ ...quote, status: 'customer_confirmed' });
          //   }
          //   return r.concat(quote);
          // }, [])
        },
        confirm: {
          isLoading: false
        }
      };

    default:
      return state;
  }
};

export default transaction;
