/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */

import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Typography } from 'antd';
import find from 'lodash/find';
import get from 'lodash/get';
import size from 'lodash/size';
import { any, arrayOf, bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LOCATION_TYPES, SALE_PRICE_TYPE } from '../../../contents/Constants';
import actions from '../../../redux/actions';
import { convertDollar, convertVND } from '../../../utils/convert';
import dispatch from '../../../utils/dispatch';
import {
  checkListWebsiteDisableUS,
  getListWebsiteDisableUS,
  getTypeAddress
} from '../../../utils/helpers';
import ModalConfirmSaleOrder from './ModalConfirmSaleOrder';
import ModalEditQuotation from './ModalEditQuotation';
import './styles.less';

function useModelConfirm() {
  const [showModelConfirm, setShowModelConfirm] = useState(false);

  const handleShowModelConfirm = () => setShowModelConfirm(true);

  const handleHideModelConfirm = () => setShowModelConfirm(false);

  return {
    visible: showModelConfirm,
    onShow: handleShowModelConfirm,
    onHide: handleHideModelConfirm
  };
}

const CartSaleOrder = ({ title, quotationList, isDeal }) => {
  const methods = useModelConfirm();
  const { onShow: onShowModelConfirm } = methods;

  const [data, setData] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const userId = useSelector(state => {
    return get(state, 'customer.userSelected._id');
  });
  const codeContent = useSelector(state => {
    return get(state, 'transaction.draftSaleOrder.payment_code', '');
  });
  const listWebsiteRejected = useSelector(state => {
    return get(state, 'website.disable', []);
  });
  const listUserAddress = useSelector(state => {
    return get(state, 'customer.userSelected.address_management');
  });
  const totalPrice = useSelector(state => {
    if (isDeal)
      return get(state, 'transactionDeal.currentDealOrder.total_last_price', 0);
    return get(state, 'transaction.draftSaleOrder.total_price_after_sale_VND', 0);
  });

  const totalPriceDeal = useSelector(state => {
    return get(state, 'transactionDeal.currentDealOrder.total_last_price', 0);
  });

  const handleDelete = key => {
    const quotationId = find(data, item => {
      return item._id === key;
    })._id;
    dispatch(actions.deleteQuotation(quotationId, userId), true);
  };

  const handleConfirmSO = () => {
    const paymentCode = `${codeContent}-VCB`;
    dispatch(actions.confirmSaleOrder(userId, paymentCode), true);
  };

  const handleConfirmDO = deal_gift_card_id => {
    const requestDate = {
      user: userId,
      payment: { type: 'gift_card', content: { deal_gift_card_id } }
    };
    dispatch(actions.confirmDealOrder(requestDate), true);
  };

  const handleEditQuotation = quotationId =>
    dispatch(actions.handleEditQuotation(quotationId));

  const columnProps = {
    align: 'left',
    ellipsis: true,
    width: 200
  };

  const columns = [
    {
      ...columnProps,
      fixed: 'left',
      title: 'Link sản phẩm',
      dataIndex: 'link'
    },
    !isDeal
      ? {
          ...columnProps,
          fixed: 'left',
          title: 'Tỉ giá',
          dataIndex: 'price_list',
          align: 'right',
          width: 125,
          render: (_, record) => {
            return convertVND(get(record, 'price_list.rate', 0));
          }
        }
      : {},
    {
      ...columnProps,
      title: 'Link hình ảnh',
      dataIndex: 'image'
    },
    {
      ...columnProps,
      title: 'Tên sản phẩm',
      dataIndex: 'name'
    },
    {
      ...columnProps,
      title: 'Màu',
      dataIndex: 'color'
    },
    {
      ...columnProps,
      title: 'Size',
      dataIndex: 'size'
    },
    {
      ...columnProps,
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'right'
    },
    {
      ...columnProps,
      title: `Giá (${
        LOCATION_TYPES.find(i => i.value === data?.[0]?.location)?.currency
      })`,
      dataIndex: 'price',
      align: 'right'
    },
    !isDeal
      ? {
          ...columnProps,
          title: 'Giá (đ)',
          dataIndex: 'price_VND',
          align: 'right',
          render: (_, record) => {
            return convertVND(get(record, 'price_VND', 0));
          }
        }
      : {},
    {
      ...columnProps,
      title: 'Giá giảm',
      dataIndex: 'sale_price_original',
      align: 'right',
      render: (text, record) => {
        // if (isDeal) return record?.price - record?.last_price;
        const salePriceType = get(record, 'sale_price_type', 'none');
        if (salePriceType !== 'none') {
          return `${text}${get(record, 'sale_price_type')}`;
        }
        return '';
      }
    },
    {
      ...columnProps,
      title: 'Hình thức',
      dataIndex: 'sale_price_type',
      render: text => {
        const result = find(SALE_PRICE_TYPE, item => {
          return item.value === text;
        });
        return get(result, 'name', '');
      }
    },
    {
      ...columnProps,
      title: 'Ghi chú',
      dataIndex: 'note'
    },
    {
      width: 200,
      fixed: 'right',
      title: 'Thao tác',
      align: 'center',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="ghost"
              onClick={() => handleEditQuotation(record._id)}
              icon={<EditOutlined />}
            >
              Sửa
            </Button>

            <Button
              type="danger"
              onClick={() => {
                if (isDeal) {
                  return dispatch(actions.deleteItemDealOrder(record._id), true);
                }
                return handleDelete(record._id);
              }}
              icon={<DeleteOutlined />}
            >
              Xoá
            </Button>
          </Space>
        );
      }
    }
  ];

  const listWebsiteDisableUS = getListWebsiteDisableUS(
    quotationList,
    listWebsiteRejected
  );

  const isMultiWebsiteInDealMode =
    isDeal && size([...new Set(quotationList.map(item => item.website))]) > 1;

  const checkDisableUS = () =>
    checkListWebsiteDisableUS(quotationList, listWebsiteRejected);

  useEffect(() => {
    setData(quotationList);
    if (getTypeAddress(listUserAddress) === 'usa' && checkDisableUS()) {
      setIsDisable(true);
    } else setIsDisable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotationList]);

  const { quotationId } = useSelector(state => {
    const quotation = state.transaction.editQuotation;
    return {
      quotationId: quotation?._id
    };
  });

  return (
    <div style={{ marginTop: 16 }}>
      <Typography.Title level={5} className="title">
        {title}
      </Typography.Title>
      <Row justify="space-between">
        <Row>
          <Col span={24}>
            <Typography.Text>
              <b>{'Tổng giá trị đơn hàng: '}</b>
              <Typography.Text mark>
                {isDeal ? convertDollar(totalPriceDeal) : convertVND(totalPrice)}
              </Typography.Text>
            </Typography.Text>
          </Col>
          {isDisable && (
            <Col span={24}>
              <Typography.Text type="danger" italic>
                <b>{`${listWebsiteDisableUS[0]} chưa hỗ trợ nhận hàng tại địa chỉ Mỹ`}</b>
              </Typography.Text>
            </Col>
          )}
          {isMultiWebsiteInDealMode && (
            <Typography.Text type="danger" italic>
              Có nhiều sản phẩm khác website trong giỏ hàng
            </Typography.Text>
          )}
        </Row>
        <Button
          icon={<CheckOutlined />}
          onClick={onShowModelConfirm}
          disabled={isDisable || isMultiWebsiteInDealMode}
          type="primary"
          className="button"
        >
          Xác nhận đơn hàng
        </Button>
      </Row>
      <Table
        bordered
        size="small"
        dataSource={data}
        scroll={{ x: '100%' }}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          pageSize: 5,
          size: 'small'
        }}
      />
      {methods.visible && (
        <ModalConfirmSaleOrder
          {...methods}
          isDeal={isDeal}
          onConfirmSaleOrder={handleConfirmSO}
          onConfirmDealOrder={handleConfirmDO}
        />
      )}
      {quotationId && <ModalEditQuotation />}
    </div>
  );
};

CartSaleOrder.propTypes = {
  isDeal: bool,
  title: string,
  quotationList: arrayOf(any)
};

CartSaleOrder.defaultProps = {
  isDeal: false,
  title: '',
  quotationList: []
};

export default CartSaleOrder;
