import { Button, Col, Form, Input, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { get } from 'lodash';
import { any, func, number, objectOf } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useCategory from '../../../hooks/useCategory';

const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

export default function Category({
  groupIndex,
  categoryIndex,
  category,
  setEditIndex
}) {
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [, handleEditName, handleDelete] = useCategory();

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      // let newValue = { ...group };
      // newValue.categories[categoryIndex] = { ...category, domain: values.domain };
      handleEditName('category', values.domain, groupIndex, categoryIndex);
      setEditIndex(prevState => {
        if (prevState.fromIndex < 0 && prevState.toIndex < 0) {
          return { fromIndex: groupIndex, toIndex: groupIndex };
        }
        return {
          fromIndex:
            prevState.fromIndex < groupIndex ? prevState.fromIndex : groupIndex,
          toIndex: prevState.toIndex > groupIndex ? prevState.toIndex : groupIndex
        };
      });
      setShowModal(false);
    });
  };

  const handleEditCategory = () => {
    form.setFieldsValue({ group_id: category.group_id, domain: category.domain });
    setShowModal(true);
  };

  const handleDeleteCategory = () => {
    handleDelete('category', category._id, groupIndex, categoryIndex);
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>{category.domain}</Col>
        <Col>
          <Button onClick={handleEditCategory} type="link">
            Chỉnh sửa
          </Button>
          <Button onClick={handleDeleteCategory} type="link">
            Xóa
          </Button>
        </Col>
      </Row>
      <Modal
        title="Sửa category"
        visible={showModal}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Category.Form form={form} {...layout} />
      </Modal>
    </>
  );
}

Category.propTypes = {
  groupIndex: number,
  categoryIndex: number,
  category: objectOf(any),
  setEditIndex: func
};

Category.defaultProps = {
  groupIndex: -1,
  categoryIndex: -1,
  category: {},
  setEditIndex: () => {}
};

Category.Form = function CategoryForm({ ...restProps }) {
  const listGroup = useSelector(state => {
    return get(state, 'category.groups.result', []);
  });
  return (
    <Form {...restProps}>
      <Form.Item name="group_id" label="Chọn group">
        <Select disabled>
          {listGroup.map((group, index) => {
            return (
              <Option key={`group-option-${index}`} value={group._id}>
                {group.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="domain"
        label="Domain"
        rules={[{ required: true, message: 'Vui lòng nhập tên của category' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
