import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Table
} from 'antd';
import find from 'lodash/find';
import get from 'lodash/get';
import size from 'lodash/size';
import { any, arrayOf, bool, element, func, objectOf, string } from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import shortid from 'shortid';
import {
  LOCATION_TYPES,
  LOCATION_TYPE_DEFAULT,
  REQUIRED_LIST,
  RULES_QUOTATION,
  SALE_PRICE_TYPE,
  SAMPLE_QUOTATION
} from '../../../contents/Constants';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import { notificationError } from '../../../utils/Notification';
import { validateData } from '../../../utils/validate';

import './styles.less';

const EditableContext = createContext(null);
const NUMBER_INPUT = ['quantity', 'price', 'salePrice'];
const SELECT_INPUT = ['salePriceType'];

const EditableRow = ({ index, isAdd, ...props }) => {
  const [form] = Form.useForm();

  const validateForm = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (error) {
      notificationError(error);
    }
  }, [form]);

  useEffect(() => {
    if (isAdd) {
      validateForm();
    }
  }, [isAdd, validateForm]);

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

EditableRow.propTypes = {
  index: string,
  isAdd: bool
};

EditableRow.defaultProps = {
  index: '',
  isAdd: false
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      });
    }
  });

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (error) {
      const data = form.getFieldValue(dataIndex);
      handleSave({ ...record, [dataIndex]: data });
    }
  };

  const inputProps = {
    onChange: save,
    style: { width: '100%' },
    id: `${dataIndex}-${get(record, 'key', '')}`
  };

  let inputNode = <Input {...inputProps} />;
  if (inputType === 'number') inputNode = <InputNumber {...inputProps} />;
  if (inputType === 'select')
    inputNode = (
      <Select {...inputProps}>
        {SALE_PRICE_TYPE.map((item, idx) => {
          return (
            <Select.Option value={item.value} key={idx}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    );

  const inputRules = find(
    RULES_QUOTATION(
      get(record, 'salePriceType', 'none'),
      get(record, 'price', 0),
      get(record, 'quantity', 1)
    ),
    item => {
      return item.dataIndex === dataIndex;
    }
  );

  return (
    <td {...restProps}>
      {editable ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: REQUIRED_LIST.includes(dataIndex),
              message: `Nhập ${title}`
            },
            ...get(inputRules, 'rules', [])
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  title: string,
  editable: bool,
  children: element,
  dataIndex: string,
  record: objectOf(any),
  handleSave: func,
  inputType: string
};

EditableCell.defaultProps = {
  title: '',
  editable: false,
  children: null,
  dataIndex: '',
  record: null,
  handleSave: () => {},
  inputType: ''
};

const handleValidate = (data, setCheck, callback) => {
  if (!validateData(data)) {
    setCheck(true);
    notificationError('Vui lòng nhập đầy đủ thông tin');
    return;
  }
  setCheck(false);
  callback();
};

const RequestSaleOrder = ({ listImport, isDeal }) => {
  const userId = useSelector(state => {
    return get(state, 'customer.userSelected._id');
  });
  const [dataSource, setDataSource] = useState([]);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    setDataSource(prevState => {
      return [...prevState, ...listImport];
    });
  }, [listImport]);

  const handleDelete = key => {
    setDataSource(
      [...dataSource].filter(item => {
        return item.key !== key;
      })
    );
  };

  const handleAdd = () => {
    handleValidate(dataSource, setIsAdd, () => {
      setDataSource([
        ...dataSource,
        { ...SAMPLE_QUOTATION, key: shortid.generate() }
      ]);
    });
  };

  const [location, setLocation] = useState(LOCATION_TYPE_DEFAULT.value);
  const onChangeLocation = event => setLocation(event.target.value);

  const quotationList = useSelector(state => {
    return get(state, 'transaction.draftSaleOrder.quotation_list', []);
  });
  const isValid = quotationList.every(item => item.location === location);

  const handleAddCart = () => {
    if (!isValid) {
      notificationError(
        'Không thể thêm sản phẩm khác tuyến với sản phẩm đang có trong giỏ hàng'
      );
      return;
    }
    handleValidate(dataSource, setIsAdd, () => {
      const requestData = [...dataSource].map(item => {
        return {
          link: get(item, 'link', ''),
          image: get(item, 'image', ''),
          name: get(item, 'name', ''),
          color: get(item, 'color', ''),
          size: get(item, 'size', ''),
          quantity: get(item, 'quantity', 1),
          price: get(item, 'price', 0),
          sale_price: get(item, 'salePrice', 0),
          sale_price_type: get(item, 'salePriceType', 'none'),
          note: get(item, 'note', ''),
          location,
          created_from: 'we'
        };
      });
      dispatch(actions.clearImportQuotation());
      if (isDeal) {
        dispatch(
          actions.addItemDealOrder({ user: userId, items: requestData }),
          true
        );
      } else {
        dispatch(actions.addQuotationToSaleOrder(userId, requestData), true);
      }
      setDataSource([]);
    });
  };

  const handleSave = row => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => {
      return row.key === item.key;
    });
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const columnProps = {
    align: 'left',
    editable: true,
    ellipsis: true,
    width: 200
  };

  const columns = [
    {
      ...columnProps,
      fixed: 'left',
      title: 'Link sản phẩm',
      dataIndex: 'link'
    },
    {
      ...columnProps,
      title: 'Link hình ảnh',
      dataIndex: 'image'
    },
    {
      ...columnProps,
      title: 'Tên sản phẩm',
      dataIndex: 'name'
    },
    {
      ...columnProps,
      title: 'Màu',
      dataIndex: 'color'
    },
    {
      ...columnProps,
      title: 'Size',
      dataIndex: 'size'
    },
    {
      ...columnProps,
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'right'
    },
    {
      ...columnProps,
      title: 'Giá',
      dataIndex: 'price',
      align: 'right'
    },
    {
      ...columnProps,
      title: 'Giá giảm',
      dataIndex: 'salePrice',
      align: 'right'
    },
    {
      ...columnProps,
      title: 'Hình thức',
      dataIndex: 'salePriceType',
      render: text => {
        const result = find(SALE_PRICE_TYPE, item => {
          return item.value === text;
        });
        return get(result, 'name', '');
      }
    },
    {
      ...columnProps,
      title: 'Ghi chú',
      dataIndex: 'note'
    },
    {
      width: 100,
      fixed: 'right',
      title: 'Thao tác',
      align: 'center',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <Button
            type="danger"
            style={{ marginBottom: 24 }}
            onClick={() => {
              return handleDelete(record.key);
            }}
            icon={<DeleteOutlined />}
          >
            Xoá
          </Button>
        );
      }
    }
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    let inputType = 'text';

    if (NUMBER_INPUT.includes(col.dataIndex)) {
      inputType = 'number';
    }

    if (SELECT_INPUT.includes(col.dataIndex)) {
      inputType = 'select';
    }

    return {
      ...col,
      onCell: record => {
        return {
          record,
          inputType,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave
        };
      }
    };
  });

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <Button onClick={handleAdd} className="button-add">
            + Thêm link
          </Button>

          <Radio.Group onChange={onChangeLocation} value={location}>
            {LOCATION_TYPES.map((item, index) => (
              <Radio value={item.value} key={index}>
                {item.icon}&nbsp;{item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Space>
        <Button
          onClick={handleAddCart}
          type="primary"
          disabled={size(dataSource) < 1}
        >
          Cho vào giỏ hàng
        </Button>
      </Row>
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell
          }
        }}
        onRow={() => {
          return { isAdd };
        }}
        size="small"
        bordered
        scroll={{ x: '100%' }}
        dataSource={dataSource}
        columns={mergedColumns}
        pagination={{
          pageSize: 5,
          size: 'small'
        }}
      />
    </>
  );
};

RequestSaleOrder.propTypes = {
  listImport: arrayOf(any),
  isDeal: bool
};

RequestSaleOrder.defaultProps = {
  listImport: [],
  isDeal: false
};

export default RequestSaleOrder;
