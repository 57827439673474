import { get, isEmpty } from 'lodash';
import { ACCESS_KEY } from '../contents/Constants';
import API from '../services/API';

export const handleUpload = image => {
  const formData = new FormData();
  formData.append('image', image);
  return fetch(API.GET_URL_IMAGE, {
    method: 'POST',
    headers: {
      authorization: window.localStorage.getItem(ACCESS_KEY)
    },
    body: formData
  }).then(response => {
    return response.json();
  });
};

const validLink = link => {
  const validURL = /(http:\/\/|https:\/\/)/;

  const isValidLink = validURL.test(link);
  if (isEmpty(link) || !isValidLink) {
    return false;
  }

  return true;
};

export const getDomain = url => {
  const isError = !validLink(url);
  if (isError) {
    return '';
  }
  const domain = new URL(url);
  return domain.hostname.replace('www.', '');
};

export const getTypeAddress = address => {
  const addressSelected = address?.filter(item => item?.selected);
  return addressSelected?.[0]?.type_addr;
};

const isIncludeHostName = (list, link) => {
  return list.some(el => {
    return link.includes(el);
  });
};

export const getListWebsiteDisableUS = (listQuotation, listWebsite) => {
  const { websites } = listQuotation.reduce(
    (result, quote) => {
      if (isIncludeHostName(listWebsite, quote?.website || '')) {
        result.websites.push(getDomain(quote?.website || ''));
      }
      return result;
    },
    {
      websites: []
    }
  );
  return websites;
};

export const checkListWebsiteDisableUS = (listQuotation, listRejectAddress) => {
  if (isEmpty(getListWebsiteDisableUS(listQuotation, listRejectAddress))) {
    return false;
  }
  return true;
};

const isNumeric = val => {
  return /^-?\d+$/.test(val);
};

export const exceptNumber = val => {
  if (isNumeric(val)) {
    return val;
  }
  return val.replace(/\D+/g, '');
};

const normalization = value => value.toString().trim().toLowerCase();

export const searchListDeal = (filter, list = []) => {
  const { search } = filter;
  let result = [];

  if (!isEmpty(search)) {
    const keyword = normalization(search);
    result = list.filter(
      item =>
        normalization(get(item, 'seller_profile.name', '')).startsWith(keyword) ||
        normalization(get(item, 'website', '')).startsWith(keyword)
    );
  } else {
    result = list;
  }
  return result;
};

export const updateStateDeal = (list = [], payload) => {
  return list.map(item => {
    if (item.skuid === payload.skuid) return { ...item, state: payload.state };
    return item;
  });
};
export const replaceOneTagHtmlByDiv = (htmlString, tag) => {
  const data = document.createElement('div');
  data.innerHTML = htmlString;
  const elements = data.getElementsByTagName(tag);
  while (elements[0]) {
    const mydiv = document.createElement('div');
    mydiv.innerHTML = elements[0].innerHTML;
    elements[0].parentNode.replaceChild(mydiv, elements[0]);
  }
  return data.innerHTML.toString();
};

export const addAttributeTag = (htmlString, tag, att, value) => {
  const data = document.createElement('div');
  data.innerHTML = htmlString;
  const elements = [...data.getElementsByTagName(tag)];
  elements.forEach(element => {
    element.setAttribute(att, value);
  });
  return data.innerHTML.toString();
};
