import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Row from 'antd/lib/row';
import Avatar from 'antd/lib/avatar';
import Space from 'antd/lib/space';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import Button from 'antd/lib/button';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import get from 'lodash/get';
import find from 'lodash/find';
import { bool, func } from 'prop-types';
import size from 'lodash/size';
import ListAddress from '../ListAddress';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import './style.less';

export default function CustomerProfile({
  isShowDeal,
  shorthand,
  isSelected,
  setIsSelected,
  setVisible
}) {
  const customer = useSelector(state => {
    return get(state, 'customer.userSelected');
  });
  const listAddress = (customer?.address_management || []).filter(addr => {
    return !addr.main_address;
  });
  const [isFullInfo, setIsFullInfo] = useState(false);

  const listDealOfUser = useSelector(
    state => state.transactionDeal.listDealOfUser || []
  );

  useEffect(() => {
    const defaultAddress = find(listAddress, { selected: true });
    setIsFullInfo(!!defaultAddress);
  }, [listAddress, customer]);

  useEffect(() => {
    if (isShowDeal) {
      dispatch(actions.getListDealByUser(customer?._id));
    }
  }, [isShowDeal, customer?._id]);

  const handleClearInput = () => {
    dispatch(actions.clearCustomer());
    dispatch(actions.clearListDealByUser());
  };

  const onSelectedCustomer = () => {
    if (isShowDeal) {
      dispatch(actions.getCurrentDealOrder(get(customer, '_id')));
    } else {
      dispatch(actions.getDraftSaleOrder(get(customer, '_id')));
    }
    setIsSelected(true);
    setVisible(false);
  };

  const onClose = () => {
    if (size(listAddress) < 1 || !isSelected) {
      dispatch(actions.clearCustomer());
    }
    setVisible(false);
  };

  // const depositValue = `${formatCurrency(
  //   get(customer, 'deposit.balance', get(customer, 'deposit', 0))
  // )} đ`;

  return (
    <>
      <Row className="customer-tags" justify="space-between" align="center">
        <Space size="large">
          <Avatar src={get(customer, 'avatar_url', '')} />
          <Text type="primary">{get(customer, 'name', '')}</Text>
        </Space>
        <Button
          style={{ border: 'none' }}
          icon={<CloseCircleOutlined />}
          onClick={handleClearInput}
        />
      </Row>
      {!shorthand && (
        <>
          <Row className="customer-info">
            <Space>
              <Avatar size={64} src={get(customer, 'avatar_url', '')} />
              <Row>
                <Title style={{ marginBottom: 0 }} level={3}>
                  {get(customer, 'name', '')}
                </Title>

                <Text className="customer-detail" type="secondary">
                  {get(customer, 'email', '')}
                  {' / '}
                  {get(customer, 'phone', '')}
                </Text>

                {/* <Space className="customer-detail" type="secondary">
              <DepositIcon />
              <Text>{'Số dư: '}</Text>
              <Text type="warning" strong>
                {depositValue}
              </Text>
            </Space> */}
              </Row>
            </Space>
          </Row>

          <ListAddress
            user={customer}
            listAddress={listAddress}
            listDeal={listDealOfUser}
          />

          <Button
            disabled={!isFullInfo}
            block
            type="primary"
            onClick={onSelectedCustomer}
          >
            Chọn khách hàng
          </Button>
          <Button block type="link" onClick={onClose}>
            Hủy bỏ thực hiện
          </Button>
        </>
      )}
    </>
  );
}

CustomerProfile.propTypes = {
  isShowDeal: bool,
  isSelected: bool,
  shorthand: bool,
  setIsSelected: func,
  setVisible: func
};

CustomerProfile.defaultProps = {
  isShowDeal: false,
  isSelected: false,
  shorthand: false,
  setIsSelected: () => {},
  setVisible: () => {}
};
