import { CloseOutlined } from '@ant-design/icons';
import {
  Badge,
  Card,
  Divider,
  Drawer,
  List,
  Table,
  Tooltip,
  Typography
} from 'antd';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSelector } from 'react-redux';
import { LOCATION_TYPES, SALE_PRICE_TYPE } from '../../../contents/Constants';
import actions from '../../../redux/actions';
import { convertVND } from '../../../utils/convert';
import dispatch from '../../../utils/dispatch';
import CardLayout from '../../Transaction/TransactionDetail/Component/CardLayout';
import ContentItem from '../../Transaction/TransactionDetail/Component/Content';
import CouponItem from '../../Transaction/TransactionDetail/Component/CouponItem';
import PriceItem from '../../Transaction/TransactionDetail/Component/PriceItem';
import Action from './component/Action';
import './style.less';

const { Link } = Typography;

export default function TransactionRetailDetail() {
  const saleOrder = useSelector(state => get(state, 'transaction.detail'));
  const showType = useSelector(state => get(state, 'transaction.showType', null));
  const isShowDetail = showType === 'detail';
  const handleClose = () => {
    dispatch(actions.clearTransactionInfo());
  };

  const coupons = get(saleOrder, 'coupons', []);

  const { totalPromotion } = coupons?.reduce(
    (result, quote) => {
      result.totalPromotion += quote?.amount;
      return result;
    },
    { totalPromotion: 0 }
  );
  const totalPriceAfterSale = get(saleOrder, 'total_price_after_sale_VND', 0);
  const weightFee = get(saleOrder, 'total_weight_fee', 0);
  const surchargeFee = get(saleOrder, 'total_surcharge_fee', 0);
  const totalPrice = totalPriceAfterSale - weightFee - surchargeFee + totalPromotion;
  const ward = get(saleOrder, 'delivery_address.ward.name', '');
  const district = get(saleOrder, 'delivery_address.district.name', '');
  const city = get(saleOrder, 'delivery_address.city.name', '');
  const typeAddr = get(saleOrder, 'delivery_address.type_addr', '');
  const street = get(saleOrder, 'delivery_address.street', '');

  const listPrice = [
    {
      title: 'Tổng giá trị sản phẩm: ',
      vnd: totalPrice
    },
    {
      title: 'Giảm giá từ BuyNgon (Khuyến mãi): ',
      vnd: totalPromotion
    },
    {
      title: 'Phí vận chuyển: ',
      vnd: weightFee
    },
    {
      title: 'Phí phụ thu: ',
      vnd: surchargeFee
    },
    {
      title: 'Số tiền cần được thanh toán:  ',
      vnd: get(saleOrder, 'total_price_after_sale_VND', 0),
      usd: totalPriceAfterSale,
      divider: true
    }
  ];

  const shippingInfo = [
    { title: 'Họ và tên:', value: get(saleOrder, 'delivery_address.name') },
    { title: 'Số điện thoại:', value: get(saleOrder, 'delivery_address.phone') },
    {
      title: 'Địa chỉ nhận hàng:',
      value:
        typeAddr === 'vietnam' ? `${street}, ${ward}, ${district}, ${city}` : street
    }
  ];

  const customerInfo = [
    { title: 'Họ và tên:', value: get(saleOrder, 'user_id[0].name', '') },
    { title: 'Số điện thoại: ', value: get(saleOrder, 'user_id[0].phone', '') },
    { title: 'Email:', value: get(saleOrder, 'user_id[0].email') }
  ];

  const columnProps = {
    align: 'left',
    ellipsis: true,
    width: 200
  };
  const columns = location => [
    {
      ...columnProps,
      fixed: 'left',
      title: 'Tên sản phẩm',
      dataIndex: 'name'
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Số lượng',
      width: 90,
      dataIndex: 'quantity',
      align: 'right'
    },
    {
      ...columnProps,
      title: 'Tỉ giá',
      dataIndex: 'price_list',
      align: 'right',
      width: 125,
      render: (_, record) => {
        return convertVND(get(record, 'price_list.rate', 0));
      }
    },
    {
      ...columnProps,
      title: 'Link sản phẩm',
      dataIndex: 'link',
      render: link => {
        return (
          <Link href={link} target="_blank">
            {link}
          </Link>
        );
      }
    },
    {
      ...columnProps,
      title: 'Link hình ảnh',
      dataIndex: 'image',
      render: link => {
        return (
          <Link href={link} target="_blank">
            {link}
          </Link>
        );
      }
    },

    {
      ...columnProps,
      title: 'Màu',
      dataIndex: 'color'
    },
    {
      ...columnProps,
      title: 'Size',
      dataIndex: 'size'
    },
    {
      ...columnProps,
      title: 'Ngành hàng',
      dataIndex: 'category',
      render: category => {
        return (
          <Tooltip title={`min weight: ${category?.min_weight}kg`}>
            {category?.vn}
          </Tooltip>
        );
      }
    },
    {
      ...columnProps,
      title: 'Trọng lượng',
      dataIndex: 'weight',
      render: w => `${w} kg`
    },
    {
      ...columnProps,
      title: 'Phí vận chuyển',
      dataIndex: 'weight_fee',
      render: fee => convertVND(fee)
    },
    {
      ...columnProps,
      title: 'Phí phụ thu',
      dataIndex: 'surcharge_fee',
      render: fee => convertVND(fee)
    },
    {
      ...columnProps,
      title: 'Phí dịch vụ',
      align: 'right',
      dataIndex: 'service_fee_VND',
      render: fee => convertVND(fee)
    },
    {
      ...columnProps,
      title: () => {
        return `Giá (${LOCATION_TYPES.find(i => i.value === location)?.currency})`;
      },
      dataIndex: 'price',
      align: 'right'
    },
    {
      ...columnProps,
      title: 'Giá (đ)',
      dataIndex: 'price_VND',
      align: 'right',
      render: (_, record) => {
        return convertVND(get(record, 'price_VND', 0));
      }
    },
    {
      ...columnProps,
      title: 'Giá giảm',
      dataIndex: 'sale_price_original',
      align: 'right',
      render: (text, record) => {
        const salePriceType = get(record, 'sale_price_type', 'none');
        if (salePriceType !== 'none') {
          return `${text}${get(record, 'sale_price_type')}`;
        }
        return '';
      }
    },
    {
      ...columnProps,
      title: 'Hình thức',
      dataIndex: 'sale_price_type',
      render: text => {
        const result = find(SALE_PRICE_TYPE, item => {
          return item.value === text;
        });
        return get(result, 'name', '');
      }
    },
    {
      ...columnProps,
      title: 'Ghi chú',
      dataIndex: 'note'
    }
  ];

  return (
    <>
      <Drawer
        className="drawer"
        width="70vw"
        title="CHI TIẾT ĐƠN HÀNG"
        placement="left"
        closeIcon={<CloseOutlined onClick={handleClose} />}
        visible={isShowDetail && !!saleOrder}
      >
        <>
          <CardLayout title="Khuyến mãi">
            {!isEmpty(coupons) &&
              coupons?.map((item, key) => (
                <CouponItem
                  name={item?.program_name}
                  price={item.amount}
                  key={key}
                />
              ))}
          </CardLayout>
          <CardLayout title="Giá trị đơn hàng">
            {listPrice.map((item, key) => (
              <>
                {item?.divider && <Divider />}
                <PriceItem
                  title={item.title}
                  vnd={item.vnd}
                  usd={item.usd}
                  divider={item?.divider}
                  key={key}
                />
              </>
            ))}
          </CardLayout>
          <CardLayout title="Thông tin giao hàng">
            {shippingInfo.map((item, key) => (
              <ContentItem title={item.title} value={item.value} key={key} />
            ))}
          </CardLayout>
          <CardLayout title="Thông tin khách hàng">
            {customerInfo.map((item, key) => (
              <ContentItem title={item.title} value={item.value} key={key} />
            ))}
          </CardLayout>
          <CardLayout title="Thông tin sản phẩm">
            <Table
              bordered
              size="small"
              dataSource={get(saleOrder, 'quotation_list', [])}
              scroll={{ x: '100%' }}
              columns={columns(saleOrder?.quotation_list?.[0]?.location)}
              rowClassName="editable-row"
              pagination={{
                pageSize: 5,
                size: 'small'
              }}
            />
          </CardLayout>
          <CardLayout title="Kiểm tra đơn hàng">
            <Action saleOrder={saleOrder} />
          </CardLayout>
          <CardLayout title="Thông tin ngân hàng">
            <List
              bordered
              dataSource={saleOrder?.bank_refer}
              renderItem={item => (
                <Badge.Ribbon
                  color="green"
                  text={item.selected && 'Được chọn'}
                  style={{ display: !item.selected && 'none' }}
                >
                  <List.Item>
                    <Card
                      style={{ width: '100%' }}
                      title={
                        <div>
                          <img src={item.path} alt="icon" />
                          <b>{item.sortName}</b>
                        </div>
                      }
                      bordered={false}
                      size="small"
                    >
                      <b>Tên chủ tài khoản:&nbsp;</b> {item.account_holder} <br />
                      <b>Số tài khoản:&nbsp;</b> {item.account_number} <br />
                      <b>Ngân hàng:&nbsp;</b> {item.name} <br />
                    </Card>
                  </List.Item>
                </Badge.Ribbon>
              )}
            />
          </CardLayout>
        </>
      </Drawer>
    </>
  );
}
