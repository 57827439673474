import { DatePicker, Space } from 'antd';
import Input from 'antd/lib/input';
import { get, intersection, isEmpty } from 'lodash';
import moment from 'moment';
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const { Search } = Input;

export default function DomainFilter({ setList }) {
  const [searchTerm, setSearchTerm] = useState({ domain: '', date: '' });
  const listWebsite = useSelector(state => {
    return get(state, 'website.list.data', { count: 0, results: [], next: false });
  });

  useEffect(() => {
    // const newSearchTerm = omitBy(searchTerm, isEmpty);
    const { domain, date } = searchTerm;
    const domainSearchList = listWebsite.results.filter(item => {
      return item.domain.includes(domain);
    });
    const dateSearchList = isEmpty(date)
      ? [...listWebsite.results]
      : listWebsite.results.filter(item => {
          return moment(item.created_at).format('DD/MM/YYYY') === date;
        });
    const result = intersection(domainSearchList, dateSearchList);
    setList(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearch = value => {
    setSearchTerm({ ...searchTerm, domain: value });
  };

  const handleSelectDate = date => {
    const dateString = date ? date.format('DD/MM/YYYY') : '';
    setSearchTerm({ ...searchTerm, date: dateString });
  };

  return (
    <Space size="middle">
      <Search name="domain" placeholder="Domain name" onSearch={handleSearch} />
      <DatePicker name="created_at" onChange={handleSelectDate} />
    </Space>
  );
}

DomainFilter.propTypes = {
  setList: func
};

DomainFilter.defaultProps = {
  setList: () => {}
};
