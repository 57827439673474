import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Row, Col, Spin } from 'antd';
import { objectOf } from 'prop-types';
import { CS_VERIFY_SO } from '../../../../contents/Constants';
import actions from '../../../../redux/actions';

const { TextArea } = Input;

export default function Action({ saleOrder }) {
  const [note, setNote] = useState('');
  const dispatch = useDispatch();

  const loading = useSelector(state => state.transaction.csVerifyRetail.isLoading);
  const onVerify = type => {
    dispatch(
      actions.csVerifyRetailSaleOrder({ type, note, sale_order_id: saleOrder._id })
    );
    setNote('');
  };

  return (
    <Spin spinning={loading}>
      <TextArea
        value={note}
        rows={4}
        placeholder="Ghi chú"
        onChange={e => setNote(e.target.value)}
      />
      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col>
          <Button type="primary" onClick={() => onVerify(CS_VERIFY_SO.confirm)}>
            Xác nhận
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => onVerify(CS_VERIFY_SO.reject)}
            danger
          >
            Huỷ đơn
          </Button>
        </Col>
      </Row>
    </Spin>
  );
}

Action.propTypes = {
  saleOrder: objectOf
};

Action.defaultProps = {
  saleOrder: {}
};
