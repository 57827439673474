import { DeleteFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Input,
  List,
  Row,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { find, get, size } from 'lodash';
import { any, arrayOf, bool, func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { shallowEqual, useSelector } from 'react-redux';
import useDraggable from '../../hooks/useDraggable';
import { DealNgonContext } from '../../pages/DealNgon';
import actions from '../../redux/actions';
import services from '../../services';
import { searchNonAccentVietnamese } from '../../utils/search';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import CustomizeCollapse from '../CustomizeCollapse';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import DragIcon from '../icons/DragIcon';

const Header = ({ icon, name, isExpand, isNew, handleDeleteCategory }) => {
  const listIcons = useSelector(state => {
    return get(state, 'dealngon.icons', []);
  });
  const linkIcon = typeof icon === 'string' ? find(listIcons, { _id: icon }) : icon;

  return (
    <Row justify="space-between">
      <Col>
        <Space size="middle">
          {!isExpand ? <ArrowDownIcon /> : <ArrowUpIcon />}
          {!isNew && linkIcon?.image && <img src={linkIcon.image} width="24px" />}
          <Typography.Text className="category-title" strong>
            {isNew ? 'New Category' : name}
          </Typography.Text>
        </Space>
      </Col>
      <Col>{!isNew && <DeleteFilled onClick={handleDeleteCategory} />}</Col>
    </Row>
  );
};

Header.propTypes = {
  icon: any,
  name: string,
  isExpand: bool,
  isNew: bool,
  handleDeleteCategory: func
};

Header.defaultProps = {
  icon: {},
  name: '',
  isExpand: false,
  isNew: false,
  handleDeleteCategory: () => {}
};

function getListingSort(data) {
  const listingSort = get(data, 'listing_sort', []);
  const websites = get(data, 'websites', []).filter(item => item.status);

  if (size(listingSort) < 1) {
    return websites;
  }

  return listingSort;
}

export default function CategoryItem({
  id,
  icon,
  name,
  isNew,
  tags_exclude,
  tags_include,
  setShowNewCategory
}) {
  const [expand, setExpand] = useState(false);
  const [listWebsite, setListWebsite] = useState([]);
  const [loading, setLoading] = useState(false);
  const listTags = useSelector(state => {
    return get(state, 'dealngon.tags', []);
  }, shallowEqual);
  const listWebsites = useSelector(state => {
    return get(state, 'dealngon.websites', []);
  }, shallowEqual);

  const handleGetTag = value => {
    const listWebToReview = [];

    value.forEach(v => {
      const list = listWebsites.filter(item => item.tags.some(tag => tag._id === v));
      listWebToReview.push(...list.filter(c => !listWebToReview.includes(c)));
    });
    setListWebsite(listWebToReview);
  };
  const [form] = useForm();
  const { tab } = useContext(DealNgonContext);

  const { onDragEnd } = useDraggable(listWebsite);
  const handleDragEnd = (fromIndex, toIndex) => {
    const result = onDragEnd(fromIndex, toIndex);
    setListWebsite(result);
  };

  const iconObject = icon?.[0];

  useEffect(() => {
    form.setFieldsValue({ icon: iconObject?._id ? iconObject._id : icon });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon]);

  const listIcons = useSelector(state => {
    return get(state, 'dealngon.icons', []);
  });

  const handleExpand = () => {
    setExpand(prevState => !prevState);
  };

  useEffect(() => {
    if (expand && !isNew && tab === 'category') {
      setLoading(true);
      services
        .getDetailDNCategory(id)
        .then(({ data }) => setListWebsite(getListingSort(data)))
        .catch(() => notificationError('Có lỗi xảy ra. Vui lòng thử lại'))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand, tab]);

  const handleSave = values => {
    if (isNew) {
      dispatch(actions.createDNCategory(values));
      setShowNewCategory(false);
    } else {
      setLoading(true);
      const request = {
        ...values,
        _id: id,
        status: true,
        listing_sort: listWebsite
      };
      services
        .updateDNCategory(request)
        .then(({ data }) => dispatch(actions.updateIconCategories(data)))
        .then(() => services.getDetailDNCategory(id))
        .then(({ data }) => setListWebsite(getListingSort(data)))
        .catch(() => notificationError('Có lỗi xảy ra. Vui lòng thử lại'))
        .finally(() => setLoading(false));
    }
  };

  const handleDeleteCategory = e => {
    dispatch(actions.updateDNCategory({ _id: id, status: false }));
    e.stopPropagation();
  };

  return (
    <CustomizeCollapse onChange={handleExpand}>
      <Collapse.Panel
        key={id}
        header={
          <Header
            icon={iconObject?._id ? iconObject : icon}
            handleDeleteCategory={handleDeleteCategory}
            name={name}
            isExpand={expand}
            isNew={isNew}
          />
        }
        showArrow={false}
      >
        <Spin spinning={loading}>
          <Form form={form} onFinish={handleSave}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Typography.Text strong type="secondary">
                  Tên category
                </Typography.Text>
                <Form.Item
                  initialValue={name}
                  name="name"
                  rules={[
                    { required: true, message: 'Vui lòng nhập tên của category' }
                  ]}
                >
                  <Input className="category-form-input" />
                </Form.Item>
                <Typography.Text strong type="secondary">
                  Icon
                </Typography.Text>
                <Form.Item
                  name="icon"
                  rules={[{ required: true, message: 'Vui lòng chọn icon' }]}
                >
                  <Select className="category-form-input">
                    {listIcons.map(({ image, _id }) => {
                      return (
                        <Select.Option key={_id} value={_id}>
                          <img src={image} alt="Icon" width="24px" />
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Typography.Text strong type="secondary">
                  Bao gồm Tags
                </Typography.Text>
                <Form.Item
                  initialValue={tags_include?.map(item =>
                    item._id ? item._id : item
                  )}
                  name="tags_include"
                  rules={[{ required: true, message: 'Vui lòng nhập Tags' }]}
                >
                  <Select
                    optionFilterProp="children"
                    filterOption={searchNonAccentVietnamese}
                    className="category-form-input"
                    mode="multiple"
                    allowClear
                    onChange={handleGetTag}
                  >
                    {listTags.map(({ _id, name: tagName }) => (
                      <Select.Option value={_id} key={_id}>
                        {tagName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Typography.Text strong type="secondary">
                  Loại trừ Tags
                </Typography.Text>
                <Form.Item
                  className="category-form-input"
                  initialValue={tags_exclude.map(item =>
                    item._id ? item._id : item
                  )}
                  name="tags_exclude"
                >
                  <Select
                    optionFilterProp="children"
                    filterOption={searchNonAccentVietnamese}
                    mode="multiple"
                    allowClear
                  >
                    {listTags.map(({ _id, name: tagName }) => {
                      return (
                        <Select.Option value={_id} key={_id}>
                          {tagName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Typography.Text strong type="secondary">
                  Vị trí các website
                </Typography.Text>
                <ReactDragListView
                  onDragEnd={handleDragEnd}
                  handleSelector=".drag-icon"
                  nodeSelector=".website-item"
                >
                  <List
                    split={false}
                    dataSource={listWebsite}
                    renderItem={({ domain }, index) => {
                      return (
                        <List.Item className="website-item">
                          <Row
                            key={`${domain}-${index}`}
                            justify="space-between"
                            align="middle"
                          >
                            <Col>
                              <Space size={24}>
                                <Typography.Text strong type="secondary">
                                  {index + 1}
                                </Typography.Text>
                                <Typography.Text>{domain}</Typography.Text>
                              </Space>
                            </Col>
                            <Col>
                              <div className="drag-icon">
                                <DragIcon />
                              </div>
                            </Col>
                          </Row>
                        </List.Item>
                      );
                    }}
                  />
                </ReactDragListView>
                <Row justify="end">
                  <Button
                    htmlType="submit"
                    className="website-button"
                    type="primary"
                  >
                    Save
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Collapse.Panel>
    </CustomizeCollapse>
  );
}

CategoryItem.propTypes = {
  id: string,
  name: string,
  isNew: bool,
  icon: arrayOf(any),
  tags_exclude: arrayOf(any),
  tags_include: arrayOf(any),
  setShowNewCategory: func
};

CategoryItem.defaultProps = {
  id: '',
  name: '',
  isNew: false,
  icon: [],
  tags_exclude: [],
  tags_include: [],
  setShowNewCategory: () => {}
};
