import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import Form from 'antd/lib/form/Form';
import { func, string, objectOf, any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from '../../../redux/actions';

export default function DomainFormItem({
  id,
  name,
  fieldKey,
  remove,
  form,
  domainId,
  setListFormatEditing,
  ...restField
}) {
  const initValue = form.getFieldValue('format')[id];
  const [value, setValue] = useState({ ...initValue });
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && value._id) {
      setListFormatEditing(prevState => {
        return [...prevState, value._id];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, value._id]);

  const handleChange = ({ target }, fieldName) => {
    const changeValue = {
      ...value,
      [fieldName]: target.value
    };
    setValue(changeValue);
    setIsEditing(true);
  };

  const handleSaveRecord = () => {
    dispatch(
      actions.editFormatWebsiteCrawler({
        _id: domainId,
        format_id: value._id,
        title: value.title,
        cls: value.cls
      })
    );
    setIsEditing(false);
    setListFormatEditing(prevState => {
      return prevState.filter(sId => {
        return sId !== value._id;
      });
    });
  };

  const handleRemove = fieldName => {
    if (!value?._id) {
      remove(fieldName);
    } else {
      // Call API
    }
  };

  return (
    <Space key={id} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
      <Form.Item
        {...restField}
        name={[name, 'title']}
        fieldKey={[fieldKey, 'title']}
        rules={[{ required: true, message: 'Missing title' }]}
      >
        <Input
          placeholder="Title"
          onChange={e => {
            return handleChange(e, 'title');
          }}
        />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'cls']}
        fieldKey={[fieldKey, 'cls']}
        rules={[{ required: true, message: 'Missing cls' }]}
      >
        <Input
          placeholder="Cls"
          onChange={e => {
            return handleChange(e, 'cls');
          }}
        />
      </Form.Item>
      {isEditing && value._id ? (
        <Button type="primary" onClick={handleSaveRecord}>
          Save
        </Button>
      ) : (
        <MinusCircleOutlined
          onClick={() => {
            return handleRemove(name);
          }}
        />
      )}
    </Space>
  );
}

DomainFormItem.propTypes = {
  id: string,
  name: string,
  fieldKey: string,
  remove: func,
  form: objectOf(any),
  domainId: string,
  setListFormatEditing: func
};

DomainFormItem.defaultProps = {
  id: '',
  name: '',
  fieldKey: null,
  remove: () => {},
  form: null,
  domainId: '',
  setListFormatEditing: () => {}
};
