import { Col, List, Row } from 'antd';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import { get } from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { useSelector } from 'react-redux';
import useCategory from '../../hooks/useCategory';
import useDraggable from '../../hooks/useDraggable';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import Category from './Category';
import Group from './Group/Group';
import './style.less';

const MODAL_CONTENT = {
  group: {
    title: 'Tạo group mới',
    key: 'group'
  },
  category: {
    title: 'Tạo category mới',
    key: 'category'
  }
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

const defaultEditIndex = { fromIndex: -1, toIndex: -1 };

export default function PriorityCategories() {
  const listGroup = useSelector(state => {
    return get(state, 'category.groups.result', []);
  });
  const { onDragEnd } = useDraggable(listGroup, actions.draggableCategories);
  const loading = useSelector(state => {
    return get(state, 'category.loading', false);
  });
  const [editIndex, setEditIndex] = useState(defaultEditIndex);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    key: ''
  });
  const [handleEdit] = useCategory();

  const [form] = Form.useForm();

  useLayoutEffect(() => {
    dispatch(actions.getAllCategories());
  }, []);

  const handleShowModal = (type, groupID) => {
    setModalContent(MODAL_CONTENT[type]);
    if (groupID) {
      form.setFieldsValue({ group_id: groupID });
    }
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = type => {
    form.validateFields().then(values => {
      const action =
        type === MODAL_CONTENT.group.key
          ? actions.createGroup
          : actions.createCategory;
      dispatch(action(values));
      form.resetFields();
    });
    setShowModal(false);
  };

  const handleDrag = (fromIndex, toIndex) => {
    setEditIndex(prevState => {
      const newEditIndex =
        fromIndex < toIndex
          ? { fromIndex, toIndex }
          : { fromIndex: toIndex, toIndex: fromIndex };
      if (prevState.fromIndex < 0 && prevState.toIndex < 0) return newEditIndex;
      return {
        fromIndex:
          prevState.fromIndex < newEditIndex.fromIndex
            ? prevState.fromIndex
            : newEditIndex.fromIndex,
        toIndex:
          prevState.toIndex > newEditIndex.toIndex
            ? prevState.toIndex
            : newEditIndex.toIndex
      };
    });
    onDragEnd(fromIndex, toIndex);
  };

  const handleSavePriority = () => {
    handleEdit(listGroup.slice(editIndex.fromIndex, editIndex.toIndex + 1));
    setEditIndex(defaultEditIndex);
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme category-container">
        <Card title="Priority Categories" className="category-card card">
          <Row justify="space-between">
            <Col>
              <Button
                onClick={() => {
                  return handleShowModal('group');
                }}
              >
                + Tạo group
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={handleSavePriority}>
                Lưu
              </Button>
            </Col>
          </Row>
          <ReactDragListView
            nodeSelector=".ant-list-item.category-group"
            onDragEnd={handleDrag}
          >
            <List
              dataSource={listGroup}
              split={false}
              renderItem={(group, index) => {
                return (
                  <List.Item className="category-group">
                    <Group
                      group={group}
                      onShowModal={handleShowModal}
                      groupIndex={index}
                      setEditIndex={setEditIndex}
                    />
                  </List.Item>
                );
              }}
            />
          </ReactDragListView>
        </Card>
      </div>
      <Modal
        title={modalContent.title}
        visible={showModal}
        onCancel={handleCancel}
        onOk={() => {
          return handleOk(modalContent.key);
        }}
      >
        {modalContent.key === MODAL_CONTENT.category.key ? (
          <Category.Form form={form} {...layout} />
        ) : (
          <Group.Form form={form} />
        )}
      </Modal>
    </Spin>
  );
}
