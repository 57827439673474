import { Drawer, Input, Space } from 'antd';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MESSAGE_CONTENT } from '../../contents/Constants';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationSuccess } from '../../utils/Notification';
import DomainFilter from './DomainFilter';
import DomainForm from './DomainForm';
import DomainTable from './DomainTable';

const Website = () => {
  const listWebsite = useSelector(state => {
    return get(state, 'website.list.data', { count: 0, results: [], next: false });
  });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [detail, setDetail] = useState(null);
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [domain, setDomain] = useState('');

  useEffect(() => {
    dispatch(actions.getWebsiteListDrawler({ limit: 1000 }));
  }, []);

  useEffect(() => {
    setList(listWebsite.results);
  }, [listWebsite]);

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleCreateDomain = () => {
    setLoading(true);
    dispatch(
      actions.createFormatWebsiteCrawler({
        data: { domain },
        callback: ({ error, data }) => {
          setLoading(false);
          setDomain('');
          setShowFormCreate(false);
          if (!error) {
            setList(data);
            notificationSuccess(MESSAGE_CONTENT.createSuccess);
          }
        }
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <Row justify="space-between">
        {!showFormCreate ? (
          <Button
            type="primary"
            onClick={() => {
              return setShowFormCreate(true);
            }}
          >
            Create domain
          </Button>
        ) : (
          <Space size="small">
            <Input
              name="domain"
              placeholder="Domain"
              onChange={({ target }) => {
                return setDomain(target.value);
              }}
            />
            <Button type="primary" onClick={handleCreateDomain}>
              Save
            </Button>
          </Space>
        )}
        <Row>
          <DomainFilter setList={setList} />
        </Row>
      </Row>
      <DomainTable
        dataSource={list}
        setDetail={setDetail}
        setShowDrawer={setShowDrawer}
      />
      <Drawer
        width={500}
        onClose={handleClose}
        visible={showDrawer}
        title="Format Info"
      >
        <DomainForm domainDetail={detail} />
      </Drawer>
    </Spin>
  );
};

export default Website;
