import API from './API';
import Restful from './Restful';

const services = {
  login: data => {
    return Restful.post(API.LOGIN, data);
  },

  getProfile: () => {
    return Restful.get(API.GET_PROFILE_OWNER);
  },

  refreshToken: refreshToken => {
    return Restful.post(API.REFRESH_TOKEN, { refresh_token: refreshToken });
  },

  getCustomerList: data => {
    return Restful.get(API.GET_CUSTOMER_LIST, data);
  },

  updateCustomer: data => {
    return Restful.put(API.UPDATE_CUSTOMER(data._id), data);
  },

  changeStatusActivityCustomer: (id, status) => {
    return Restful.put(API.CHANGE_STATUS_ACTIVITY_CUSTOMER, { id, status });
  },

  changeStatusActivityAccount: (id, status) => {
    return Restful.put(API.CHANGE_STATUS_ACTIVITY_ACCOUNT, { id, status });
  },

  searchCustomer: data => {
    return Restful.get(API.SEARCH_CUSTOMER, data);
  },

  getAccountList: data => {
    return Restful.get(API.GET_ACCOUNT_LIST, data);
  },

  updateAccount: data => {
    return Restful.put(API.UPDATE_ACCOUNT, data);
  },

  searchAccount: data => {
    return Restful.get(API.SEARCH_ACCOUNT, data);
  },

  getTransactionList: params => {
    return Restful.get(API.GET_TRANSACTION_LIST, params);
  },

  getDepositList: ids => {
    return Restful.post(API.GET_DEPOSIT_LIST, ids);
  },

  getPointList: ids => {
    return Restful.post(API.GET_POINT_LIST, ids);
  },

  acceptTransaction: ({
    customerId,
    saleOrderId,
    paymentImg,
    paymentRef,
    priceTranfer,
    paidInfo
  }) => {
    return Restful.post(API.ACCEPT_TRANSACTION, {
      customer_id: customerId,
      sale_order_id: saleOrderId,
      payment_img: paymentImg,
      payment_ref: paymentRef,
      price_tranfer: priceTranfer,
      paid_info: paidInfo
    });
  },

  rejectTransaction: ({ customerId, saleOrderId }) => {
    return Restful.post(API.REJECT_TRANSACTION, {
      customer_id: customerId,
      sale_order_id: saleOrderId
    });
  },

  updateFcmToken: fcmToken => {
    return Restful.put(API.UPDATE_ACCOUNT, { fcm_token: fcmToken });
  },

  getConfiguration: () => {
    return Restful.get(API.CONFIGURATION);
  },

  updateConfiguration: data => {
    return Restful.put(API.CONFIGURATION, data);
  },

  getDraftSaleOrder: userId => {
    return Restful.get(API.GET_DRAFT_SALE_ORDER(userId));
  },

  addQuotationToSaleOrder: data => {
    return Restful.put(API.ADD_QUOTATION_TO_SALE_ORDER, data);
  },

  editQuotation: (quotationId, userId, data) => {
    return Restful.put(API.EDIT_QUOTATION(quotationId, userId), data);
  },

  deleteQuotation: (quotationId, userId) => {
    return Restful.delete(API.DELETE_QUOTATION(quotationId, userId));
  },

  getDeliveryAddress: userId => {
    return Restful.get(API.GET_DELIVERY_ADDRESS(userId));
  },

  createDeliveryAddress: (userId, address) => {
    return Restful.put(API.CREATE_DELIVERY_ADDRESS(userId), {
      delivery_address: address
    });
  },

  selectedDeliveryAddress: (addressId, userId) => {
    return Restful.put(API.SELECTED_DELIVERY_ADDRESS(addressId, userId));
  },

  confirmSaleOrder: data => {
    return Restful.post(API.CONFIRM_SALE_ORDER, data);
  },

  getUrlImage: image => {
    return Restful.post(API.GET_URL_IMAGE, image);
  },

  getAllCustomers: () => {
    return Restful.get(API.GET_ALL_CUSTOMERS);
  },

  getAllProvince: () => {
    return Restful.get(API.GET_ALL_PROVINCE);
  },

  getAllDistrict: provinceId => {
    return Restful.get(API.GET_ALL_DISTRICT(provinceId));
  },

  getAllWard: (provinceId, districtId) => {
    return Restful.get(API.GET_ALL_WARD(provinceId, districtId));
  },

  importQuotation: file => {
    return Restful.upload(API.IMPORT_QUOTATION, file);
  },

  getLogsCancel: data => {
    return Restful.get(API.GET_LOG, data);
  },

  getWebsiteList: data => {
    return Restful.get(API.GET_WEBSILTE_LIST_CRAWLER, data);
  },

  addFormatWebsiteCrawler: data => {
    return Restful.post(API.ADD_FORMAT_WEBSITE_CRAWLER, data);
  },

  editFormatWebsiteCrawler: data => {
    return Restful.put(API.EDIT_FORMAT_WEBSITE_CRAWLER, data);
  },

  createFormatWebsiteCrawler: data => {
    return Restful.post(API.CREATE_FORMAT_WEBSITE_CRAWLER, data);
  },

  getAllCategories: () => {
    return Restful.get(API.GET_ALL_CATEGORIES);
  },

  createGroup: body => {
    return Restful.post(API.CREATE_GROUP, body);
  },

  createCategory: body => {
    return Restful.post(API.CATEGORY, body);
  },

  updateCategories: body => {
    return Restful.put(API.CATEGORY_IMPROVEMENT, body);
  },

  updateCategoriesImprovement: body => {
    return Restful.put(API.CATEGORY_IMPROVEMENT, body);
  },

  deleteCategory: body => {
    return Restful.delete(API.DELETE_CATEGORY(body.type, body.id));
  },

  getListWebsiteDisableUs: () => {
    return Restful.get(API.GET_LIST_WEBSITE_DISABLE_US);
  },

  // Deal Ngon
  createTag: body => {
    return Restful.post(API.TAG, body);
  },

  getListTags: () => {
    return Restful.get(API.TAG);
  },

  updateTag: body => {
    return Restful.put(API.TAG, body);
  },

  createWebsite: body => {
    return Restful.post(API.WEBSITE, body);
  },

  getListWebsites: () => {
    return Restful.get(API.WEBSITE);
  },

  updateWebsite: body => {
    return Restful.put(API.WEBSITE, body);
  },

  createDNCategory: body => {
    return Restful.post(API.DN_CATEGORY, body);
  },

  updateDNCategory: body => {
    return Restful.put(API.DN_CATEGORY, body);
  },

  getListDNCategories: () => {
    return Restful.get(API.GET_LIST_DN_CATEGORIES);
  },

  getDetailDNCategory: id => {
    return Restful.get(API.GET_DETAIL_DN_CATEGORY(id));
  },

  createIcon: body => {
    return Restful.post(API.ICON, body);
  },

  getListIcons: () => {
    return Restful.get(API.ICON);
  },

  deleteIcon: id => {
    return Restful.delete(API.DELETE_ICON(id));
  },

  deleteTag: id => {
    return Restful.delete(API.DELETE_TAG(id));
  },

  deleteWebsite: id => {
    return Restful.delete(API.DELETE_WEBSITE(id));
  },

  getShippingCategories: () => Restful.get(API.GET_SHIPPING_CATEGORIES()),

  csVerifyRetailSaleOrder: body =>
    Restful.post(API.CS_VERIFY_RETAIL_SALE_ORDER, body),

  getListPaymentContent: data => {
    return Restful.get(API.GET_LIST_PAYMENT_CONTENT, data);
  },

  createPaymentContent: data => {
    return Restful.post(API.CREATE_PAYMENT_CONTENT, data);
  },

  getWithdrawalList: params => {
    return Restful.get(API.GET_LIST_WITHDRAWAL, params);
  },

  getListBank: () => {
    return Restful.get(API.GET_LIST_BANK);
  },

  rejectWithdrawal: data => {
    return Restful.post(API.REJECT_WITHDRAWAL, data);
  },

  approveWithdrawal: data => {
    return Restful.post(API.APPROVE_WITHDRAWAL, data);
  },

  getListExchangePointRequest: data => {
    return Restful.get(API.GET_LIST_EXCHANGE_POINT_REQUEST, data);
  },

  confirmExchangePointRequest: data => {
    return Restful.post(API.CONFIRM_EXCHANGE_POINT_REQUEST, data);
  },

  rejectExchangePointRequest: data => {
    return Restful.post(API.REJECT_EXCHANGE_POINT_REQUEST, data);
  },

  updatePointByAdmin: data => {
    return Restful.put(API.UPDATE_POINT_BY_ADMIN, data);
  },

  exportPointStatistical: data => {
    return Restful.get(API.EXPORT_POINT_STATISTICAL, data);
  },

  geListPromotionUs: () => Restful.get(API.GET_LIST_PROMOTION_US),
  deletePromotionUs: data => Restful.delete(API.DELETE_PROMOTION_US(data)),
  createPromotionUs: data => {
    return Restful.post(API.CREATE_PROMOTION_US, data);
  },
  updatePromotionUs: data => Restful.put(API.UPDATE_PROMOTION_US(data._id), data),
  reorderSaleOrder: body => Restful.post(API.REORDER_SALE_ORDER, body),
  updateWaitingSaleOrder: body => Restful.post(API.UPDATE_WAITING_SALE_ORDER, body),
  handleEdit: (params, body) => Restful.put(API.HANDLE_EDIT(params), body),

  getListDealWithdraw: query => {
    return Restful.get(API.GET_LIST_DEAL_WITHDRAW, query);
  },

  createDealWithdrawRequest: body => {
    return Restful.post(API.CREATE_DEAL_WITHDRAW_REQUEST, body);
  },

  confirmDealWithdraw: body => {
    return Restful.post(API.CONFIRM_DEAL_WITHDRAW, body);
  },

  rejectDealWithdraw: body => {
    return Restful.post(API.REJECT_DEAL_WITHDRAW, body);
  },

  marketplace: {
    transaction: {
      getCurrentDealOrder: query => {
        return Restful.get(API.GET_CURRENT_DEAL_ORDER(query));
      },
      confirmDealOrder: body => {
        return Restful.post(API.CONFIRM_DEAL_ORDER, body);
      },
      getListDealByUser: query => {
        return Restful.get(API.GET_LIST_DEAL_BY_USER(query));
      },
      deleteItemDealOrder: query => {
        return Restful.delete(API.DELETE_ITEM_DEAL_ORDER(query));
      },
      addItemDealOrder: body => {
        return Restful.post(API.ADD_ITEM_DEAL_ORDER, body);
      },
      list: params => Restful.get(API.GET_LIST_DEAL, params),
      detail: id => Restful.get(API.GET_DEAL_DETAIL(id)),
      confirm: body => Restful.post(API.CONFIRM_DEAL, body),
      reject: body => Restful.post(API.REJECT_DEAL, body),
      listDealGiftCardTransaction: query => {
        return Restful.get(API.LIST_DEAL_GIFT_CARD_TRANSACTION, query);
      }
    },

    metadeal: {
      listMeta: () => Restful.get(API.GET_LIST_META_DEAL),
      createDeal: data => Restful.post(API.CREATE_DEAL, data),
      updateStateDeal: data => Restful.post(API.UPDATE_STATE_DEAL, data)
    },
    seller: {
      listProfile: query => Restful.get(API.LIST_SELLER_PROFILE, query),
      createProfile: data => Restful.post(API.CREATE_SELELR_PROFILE, data),
      editProfile: data => Restful.put(API.EDIT_SELLER_PROFILE, data)
    },
    category: {
      list: () => Restful.get(API.LIST_CATEGORY)
    },
    topic: {
      list: () => Restful.get(API.LIST_TOPIC)
    },
    campaign: {
      list: params => Restful.get(API.LIST_CAMPAIGN_PROMOTION, params),
      create: data => Restful.post(API.CREATE_CAMPAIGN_PROMOTION, data),
      update: data => Restful.put(API.UPDATE_CAMPAIGN_PROMOTION, data),
      history: id => Restful.get(API.GET_CAMPAIGN_HISTORY(id))
    },
    bankTransfer: {
      pool: () => Restful.get(API.GET_BANK_TRANSFER_POOL),
      listVas: () => Restful.get(API.GET_LIST_VA)
    },
    debtRecovery: {
      list: () => Restful.get(API.GET_LIST_DEBT_RECOVERY),
      create: data => Restful.post(API.CREATE_DEBT_RECOVERY, data),
      close: id => Restful.put(API.CLOSE_DEBT_RECOVERY(id))
    }
  }
};

export default services;
