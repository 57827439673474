import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Row, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Form from 'antd/lib/form';
import { get, isEmpty } from 'lodash';
import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import DomainFormItem from './DomainFormItem';

export default function DomainForm({ domainDetail }) {
  const [form] = Form.useForm();
  const [listFormatEditing, setListFormatEditing] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      domain: get(domainDetail, 'domain', ''),
      format: get(domainDetail, 'format', []),
      type: get(domainDetail, 'type', ''),
      disable: get(domainDetail, 'disable', false),
      resourceType: get(domainDetail, 'resourceType', '')
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainDetail]);

  const onSave = () => {
    form.validateFields().then(values => {
      const { domain, format, disable, type, resourceType } = values;
      const addNewFormats = format.filter(item => !item._id);
      dispatch(
        actions.updateCrawlWebsite({
          domain: {
            _id: domainDetail._id,
            domain,
            disable,
            type,
            resourceType
          },
          format: { _id: domainDetail._id, format: addNewFormats }
        })
      );
    });
  };

  return (
    <Form form={form}>
      <Typography.Title level={5}>Domain:</Typography.Title>
      <Form.Item
        name="domain"
        rules={[{ required: true, message: 'Missing domain name' }]}
      >
        <Input placeholder="Domain" />
      </Form.Item>
      <Form.Item name="type">
        <Input placeholder="Type" />
      </Form.Item>
      <Form.Item name="resourceType">
        <Input placeholder="ResourceType" />
      </Form.Item>
      <Form.Item name="disable" valuePropName="checked">
        <Checkbox>Không hỗ trợ địa chỉ Mỹ</Checkbox>
      </Form.Item>

      <Typography.Title level={5}>Domain Format:</Typography.Title>
      <Form.List name="format">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <DomainFormItem
                key={key}
                id={key}
                domainId={domainDetail._id}
                name={name}
                fieldKey={fieldKey}
                remove={remove}
                setListFormatEditing={setListFormatEditing}
                form={form}
                {...restField}
              />
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Row justify="center">
          <Button
            type="primary"
            htmlType="submit"
            onClick={onSave}
            disabled={!isEmpty(listFormatEditing)}
          >
            Save
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
}

DomainForm.propTypes = {
  domainDetail: any
};

DomainForm.defaultProps = {
  domainDetail: null
};
