import { DeleteFilled } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Space, Typography } from 'antd';
import Collapse from 'antd/lib/collapse';
import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { get, isEmpty } from 'lodash';
import { any, arrayOf, bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import { searchNonAccentVietnamese } from '../../utils/search';
import CustomizeCollapse from '../CustomizeCollapse';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import UploadImage from '../UploadImage';

const { Panel } = Collapse;

export default function WebsiteItem({
  id,
  name,
  domain,
  image,
  tags,
  icon,
  isNew,
  setShowAddWebsite
}) {
  const [expand, setExpand] = useState(false);
  const [file, handleUploadFile, handleReset] = useUploadFile(image);
  const listTags = useSelector(state => {
    return get(state, 'dealngon.tags', []);
  }, shallowEqual);
  const [form] = useForm();

  const iconObject = icon?.[0];

  useEffect(() => {
    form.setFieldsValue({ icon: iconObject?._id ? iconObject._id : icon });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon]);

  const listIcons = useSelector(state => {
    return get(state, 'dealngon.icons', []);
  });

  const handleDelete = e => {
    // call API delete
    dispatch(actions.updateWebsite({ _id: id, status: false }));
    e.stopPropagation();
  };

  const handleSave = values => {
    if (isEmpty(file.link)) {
      notificationError('Vui lòng nhập đầy đủ thông tin');
    } else if (isNew) {
      // call Create
      dispatch(
        actions.createWebsite({
          name: get(values, 'website_name', ''),
          domain: get(values, 'domain'),
          image: file.link ? file.link : image,
          icon: get(values, 'icon', null),
          tags: get(values, 'tags', [])
        })
      );
      setShowAddWebsite(false);
    } else {
      dispatch(
        actions.updateWebsite({
          name: get(values, 'website_name', ''),
          domain: get(values, 'domain'),
          icon: get(values, 'icon', null),
          image: file.link ? file.link : image,
          tags: get(values, 'tags', []),
          _id: id
        })
      );
    }
  };

  const header = (
    <Row justify="space-between" align="middle">
      <Space align="center" size="middle">
        {expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
        <Typography.Text strong>{isNew ? 'New Website' : domain}</Typography.Text>
      </Space>
      {!isNew && (
        <Col>
          <DeleteFilled onClick={handleDelete} />
        </Col>
      )}
    </Row>
  );

  return (
    <CustomizeCollapse
      onChange={() => {
        return setExpand(prevState => {
          return !prevState;
        });
      }}
    >
      <Panel header={header} showArrow={false}>
        <Form form={form} onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text
                strong
                type="secondary"
                rules={[{ required: true, message: 'Vui lòng nhập domain' }]}
              >
                Domain
              </Typography.Text>
              <Form.Item name="domain" initialValue={domain}>
                <Input placeholder="Nhập domain" />
              </Form.Item>
              <Typography.Text strong type="secondary">
                Content
              </Typography.Text>
              <Form.Item name="website_name" initialValue={name}>
                <Input placeholder="Content" />
              </Form.Item>
              <Typography.Text strong type="secondary">
                Bao gồm Tags
              </Typography.Text>
              <Form.Item
                initialValue={tags.map(tag => {
                  return tag._id ? tag._id : tag;
                })}
                name="tags"
              >
                <Select
                  optionFilterProp="children"
                  filterOption={searchNonAccentVietnamese}
                  mode="multiple"
                  allowClear
                >
                  {listTags.map(({ _id, name: tagName }) => {
                    return <Select.Option key={_id}>{tagName}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Typography.Text strong type="secondary">
                Logo
              </Typography.Text>
              <Form.Item
                name="icon"
                rules={[{ required: true, message: 'Vui lòng chọn tên của icon' }]}
              >
                <Select className="category-form-input">
                  {listIcons.map(({ image: imageIcon, _id }) => {
                    return (
                      <Select.Option key={_id} value={_id}>
                        <img src={imageIcon} alt="Icon" width="24px" />
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Typography.Text strong type="secondary">
                Ảnh
              </Typography.Text>
              <UploadImage
                isPreventCrop
                imgUrl={file.link}
                beforeUpload={handleUploadFile}
                loading={file.isFetching}
                handleReset={handleReset}
                isNew={isNew}
              />
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 8 }}>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Row>
        </Form>
      </Panel>
    </CustomizeCollapse>
  );
}

WebsiteItem.propTypes = {
  id: string,
  name: string,
  domain: string,
  image: string,
  tags: arrayOf(any),
  icon: arrayOf(any),
  isNew: bool,
  setShowAddWebsite: func
};

WebsiteItem.defaultProps = {
  id: '',
  name: '',
  domain: '',
  image: '',
  tags: [],
  icon: [],
  isNew: false,
  setShowAddWebsite: () => {}
};
