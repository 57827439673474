import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Row from 'antd/lib/row';
import Avatar from 'antd/lib/avatar';
import Space from 'antd/lib/space';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import AutoComplete from 'antd/lib/auto-complete';
import Input from 'antd/lib/input';
import Spin from 'antd/lib/spin';
import UserOutlined from '@ant-design/icons/UserOutlined';
import './style.less';
import get from 'lodash/get';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';
import useTyping from '../../hooks/useTyping';
import { LIMIT_PAGE_DEFAULT } from '../../contents/Constants';

function SearchCustomer() {
  const { count, customers } = useSelector(state => {
    return get(state, 'customer.list');
  });
  const { isLoading } = useSelector(state => {
    return get(state, 'customer');
  });
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState('');

  useEffect(() => {
    if (searchParams) {
      setResult(prevCustomers => {
        return [...prevCustomers, ...customers];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useTyping(() => {
    if (searchParams.length !== 0) {
      dispatch(
        actions.searchCustomer({
          searchParams,
          page,
          limit: LIMIT_PAGE_DEFAULT
        })
      );
    }
  }, [searchParams, page]);

  const options = result.map(customer => {
    return {
      key: customer._id,
      value: customer._id,
      label: (
        <Space>
          <Avatar src={customer.avatar_url} />
          <Row>
            <Row>
              <Title style={{ marginBottom: 0 }} level={5}>
                {customer.name}
              </Title>
            </Row>
            <Row className="customer-detail">
              <Space>
                <Text type="secondary">{customer.email}</Text>
                <Text type="secondary">{customer.phone}</Text>
              </Space>
            </Row>
          </Row>
        </Space>
      )
    };
  });

  if (isLoading) {
    const loadingComponent = {
      key: 'loadingComponent',
      value: 'loading',
      label: (
        <Row justify="center">
          <Spin />
        </Row>
      )
    };
    options.push(loadingComponent);
  }

  const handleSearch = value => {
    setPage(1);
    setResult([]);
    setSearchParams(value || '');
  };

  const handleSelect = value => {
    const customer = result.find(item => {
      return item._id === value;
    });
    dispatch(actions.selectCustomer(customer));
  };

  const isReachBottom = target => {
    return target.scrollHeight - target.scrollTop === target.clientHeight;
  };

  const handleScroll = e => {
    const { target } = e;
    if (isReachBottom(target)) {
      if (page < Math.ceil(count / LIMIT_PAGE_DEFAULT)) {
        setPage(page + 1);
      }
    }
  };

  return (
    <AutoComplete
      className="autocomplete"
      onSearch={handleSearch}
      onSelect={handleSelect}
      options={options}
      onScroll={handleScroll}
    >
      <Input
        className="input-search"
        prefix={<Avatar icon={<UserOutlined />} />}
        placeholder="Nhập tên / email / số điện thoại"
      />
    </AutoComplete>
  );
}

export default SearchCustomer;
