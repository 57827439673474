import { isEmpty, size } from 'lodash';

const validateLink = link => {
  if (isEmpty(link)) {
    return false;
  }

  const isValidLink = /(http:\/\/|https:\/\/)/.test(link);
  if (!isValidLink) {
    return false;
  }

  return true;
};

const validateName = name => {
  return !isEmpty(name);
};

const validatePrice = price => {
  if (!parseFloat(price)) {
    return false;
  }
  return true;
};

const validateSalePrice = (price, salePrice, salePriceType) => {
  if (salePriceType === '%') {
    if (+salePrice < 0 || +salePrice > 100) return false;
  }
  if (salePriceType === '$') {
    if (!price) {
      return false;
    }
    if (+salePrice < 0 || +salePrice > price) {
      return false;
    }
  }
  return true;
};

const validateData = data => {
  if (size(data) === 0) {
    return true;
  }

  return data.every(item => {
    return (
      validateLink(item.link) &&
      validateName(item.name) &&
      validatePrice(item.price) &&
      validateSalePrice(item.price, item.salePrice, item.salePriceType)
    );
  });
};

export {
  validateLink,
  validateName,
  validatePrice,
  validateSalePrice,
  validateData
};
