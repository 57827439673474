import { HOST } from '../utils/domain';

export default {
  LOGIN: `${HOST}/admin/auth/login`,

  LOGOUT: `${HOST}/admin/auth/logout`,

  REFRESH_TOKEN: `${HOST}/admin/auth/refresh-token`,

  GET_PROFILE_OWNER: `${HOST}/admin/account/profile`,

  CHANGE_STATUS_ACTIVITY_CUSTOMER: `${HOST}/admin/customer/change-status`,

  CHANGE_STATUS_ACTIVITY_ACCOUNT: `${HOST}/admin/account/change-status`,

  SEARCH_CUSTOMER: `${HOST}/admin/customer/search`,

  UPDATE_CUSTOMER: id => {
    return `${HOST}/admin/customer/profile/${id}`;
  },

  GET_ACCOUNT_LIST: `${HOST}/admin/account/list`,

  UPDATE_ACCOUNT: `${HOST}/admin/account/profile`,

  SEARCH_ACCOUNT: `${HOST}/admin/account/search`,

  GET_TRANSACTION_LIST: `${HOST}/admin/sale-orders/list`,

  GET_DEPOSIT_LIST: `${HOST}/admin/customer/list-deposit`,

  GET_POINT_LIST: `${HOST}/admin/customer/list-point`,

  ACCEPT_TRANSACTION: `${HOST}/admin/sale-orders/confirm`,

  REJECT_TRANSACTION: `${HOST}/admin/sale-orders/reject`,

  CONFIGURATION: `${HOST}/admin/configuration`,

  GET_DRAFT_SALE_ORDER: userId => {
    return `${HOST}/admin/sale-orders/current/${userId}`;
  },

  ADD_QUOTATION_TO_SALE_ORDER: `${HOST}/admin/sale-orders/update`,

  EDIT_QUOTATION: (SOId, userId) => {
    return `${HOST}/admin/sale-orders/update/${SOId}/${userId}`;
  },

  DELETE_QUOTATION: (SOId, userId) => {
    return `${HOST}/admin/sale-orders/delete-quotation/${SOId}/${userId}`;
  },

  GET_DELIVERY_ADDRESS: userId => {
    return `${HOST}/admin/customer/detail/${userId}`;
  },

  CREATE_DELIVERY_ADDRESS: userId => {
    return `${HOST}/admin/sale-orders/create-address/${userId}`;
  },

  SELECTED_DELIVERY_ADDRESS: (addressId, userId) => {
    return `${HOST}/admin/sale-orders/address-sellected/${addressId}/${userId}`;
  },

  CONFIRM_SALE_ORDER: `${HOST}/admin/sale-orders/create`,

  GET_URL_IMAGE: `${HOST}/public/upload/image`,

  GET_ALL_CUSTOMERS: `${HOST}/admin/customer/get-all`,

  GET_ALL_PROVINCE: `${HOST}/public/location/province`,

  GET_ALL_DISTRICT: provinceId => {
    return `${HOST}/public/location/province/${provinceId}/district`;
  },

  GET_ALL_WARD: (provinceId, districtId) => {
    return `${HOST}/public/location/province/${provinceId}/district/${districtId}/ward`;
  },

  IMPORT_QUOTATION: `${HOST}/admin/sale-orders/upload`,

  GET_LOG: `${HOST}/admin/log`,

  GET_WEBSILTE_LIST_CRAWLER: `${HOST}/admin/website/list`,

  ADD_FORMAT_WEBSITE_CRAWLER: `${HOST}/admin/website/add`,

  EDIT_FORMAT_WEBSITE_CRAWLER: `${HOST}/admin/website/format`,

  CREATE_FORMAT_WEBSITE_CRAWLER: `${HOST}/admin/website`,

  GET_ALL_CATEGORIES: `${HOST}/customer/categories/list`,

  CREATE_GROUP: `${HOST}/admin/categories/group`,

  CATEGORY: `${HOST}/admin/categories`,

  CATEGORY_IMPROVEMENT: `${HOST}/admin/dn-categories/listing-sort`,

  DELETE_CATEGORY: (type, id) => {
    return `${HOST}/admin/categories?type=${type}&_id=${id}`;
  },

  GET_LIST_WEBSITE_DISABLE_US: `${HOST}/admin/website/disabled`,

  // Deal Ngon API
  TAG: `${HOST}/admin/tag`,

  WEBSITE: `${HOST}/admin/dn-website`,

  DN_CATEGORY: `${HOST}/admin/dn-categories`,

  GET_LIST_DN_CATEGORIES: `${HOST}/admin/dn-categories/list`,

  GET_DETAIL_DN_CATEGORY: id => {
    return `${HOST}/admin/dn-categories/detail/${id}`;
  },

  ICON: `${HOST}/admin/icon`,

  DELETE_ICON: id => {
    return `${HOST}/admin/icon/${id}`;
  },

  DELETE_TAG: id => {
    return `${HOST}/admin/tag/${id}`;
  },

  DELETE_WEBSITE: id => {
    return `${HOST}/admin/website/${id}`;
  },

  GET_SHIPPING_CATEGORIES: () => `${HOST}/admin/shipping`,

  CS_VERIFY_RETAIL_SALE_ORDER: `${HOST}/admin/sale-orders/cs-verify-retail-sale-order`,

  GET_LIST_PAYMENT_CONTENT: `${HOST}/admin/payment-content/list`,

  CREATE_PAYMENT_CONTENT: `${HOST}/admin/payment-content`,

  GET_LIST_WITHDRAWAL: `${HOST}/admin/withdrawal`,

  GET_LIST_BANK: `${HOST}/admin/bank`,

  REJECT_WITHDRAWAL: `${HOST}/admin/withdrawal/reject`,

  APPROVE_WITHDRAWAL: `${HOST}/admin/withdrawal/create-and-post-payment`,

  GET_LIST_EXCHANGE_POINT_REQUEST: `${HOST}/admin/exchange_point_request/list`,

  CONFIRM_EXCHANGE_POINT_REQUEST: `${HOST}/admin/exchange_point_request/confirm`,

  REJECT_EXCHANGE_POINT_REQUEST: `${HOST}/admin/exchange_point_request/reject`,

  UPDATE_POINT_BY_ADMIN: `${HOST}/admin/exchange_point_request/point`,

  EXPORT_POINT_STATISTICAL: `${HOST}/admin/exchange_point_request/export`,

  GET_LIST_PROMOTION_US: `${HOST}/admin/us-promotions/list`,

  DELETE_PROMOTION_US: id => `${HOST}/admin/us-promotions/delete/${id}`,

  CREATE_PROMOTION_US: `${HOST}/admin/us-promotions/create`,

  UPDATE_PROMOTION_US: id => `${HOST}/admin/us-promotions/update/${id}`,

  // market place

  GET_CURRENT_DEAL_ORDER: user_id =>
    `${HOST}/mp/admin/sale-order-v2/current/${user_id}`,

  CONFIRM_DEAL_ORDER: `${HOST}/mp/admin/sale-order-v2/confirm`,

  GET_LIST_DEAL_BY_USER: user_id => `${HOST}/mp/admin/deal-gift-card/${user_id}`,

  DELETE_ITEM_DEAL_ORDER: deal_id => `${HOST}/mp/admin/sale-order-v2/${deal_id}`,

  ADD_ITEM_DEAL_ORDER: `${HOST}/mp/admin/sale-order-v2/add`,

  GET_LIST_DEAL: `${HOST}/mp/admin/purchase-transaction`,

  GET_LIST_META_DEAL: `${HOST}/mp/admin/deal`,

  GET_DEAL_DETAIL: id => `${HOST}/mp/admin/purchase-transaction/${id}`,

  CONFIRM_DEAL: `${HOST}/mp/admin/purchase-transaction/confirm`,

  REJECT_DEAL: `${HOST}/mp/admin/purchase-transaction/reject`,

  // REORDER_SALE_ORDER: `${HOST}/admin/sale-orders/reorder-sale-order`, version cu
  REORDER_SALE_ORDER: `${HOST}/mp/admin/sale-order-v2/reorder-sale-order`,

  HANDLE_EDIT: ({ soId, userId }) =>
    `${HOST}/admin/sale-orders/update/${soId}/${userId}`,

  LIST_DEAL_GIFT_CARD_TRANSACTION: `${HOST}/mp/admin/deal-gift-card-transaction`,

  LIST_SELLER_PROFILE: `${HOST}/mp/admin/seller-profile`,

  CREATE_SELELR_PROFILE: `${HOST}/mp/admin/seller-profile`,

  CREATE_DEAL: `${HOST}/mp/admin/deal`,

  EDIT_SELLER_PROFILE: `${HOST}/mp/admin/seller-profile`,

  LIST_CATEGORY: `${HOST}/mp/admin/product-buyngon/list-category`,

  LIST_TOPIC: `${HOST}/mp/admin/product-buyngon/list-topic`,

  UPDATE_STATE_DEAL: `${HOST}/mp/admin/deal/update-state`,

  GET_LIST_DEAL_WITHDRAW: `${HOST}/mp/admin/withdraw-all-deal-gift-card`,

  CREATE_DEAL_WITHDRAW_REQUEST: `${HOST}/mp/admin/withdraw-all-deal-gift-card`,

  CONFIRM_DEAL_WITHDRAW: `${HOST}/mp/admin/withdraw-all-deal-gift-card/confirm`,

  REJECT_DEAL_WITHDRAW: `${HOST}/mp/admin/withdraw-all-deal-gift-card/reject`,

  UPDATE_WAITING_SALE_ORDER: `${HOST}/mp/admin/sale-order-v2/update-waiting-sale-order`,

  CREATE_CAMPAIGN_PROMOTION: `${HOST}/mp/admin/campaign-promotion`,

  LIST_CAMPAIGN_PROMOTION: `${HOST}/mp/admin/campaign-promotion`,

  UPDATE_CAMPAIGN_PROMOTION: `${HOST}/mp/admin/campaign-promotion`,

  GET_CAMPAIGN_HISTORY: id => `${HOST}/mp/admin/campaign-promotion/history/${id}`,

  GET_BANK_TRANSFER_POOL: `${HOST}/mp/admin/bank-transfer/pool`,

  GET_LIST_VA: `${HOST}/mp/admin/bank-transfer/virtual-account-appota`,

  GET_LIST_DEBT_RECOVERY: `${HOST}/mp/admin/debt-recovery`,

  CREATE_DEBT_RECOVERY: `${HOST}/mp/admin/debt-recovery`,

  CLOSE_DEBT_RECOVERY: id => `${HOST}/mp/admin/debt-recovery/${id}`
};
