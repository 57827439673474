import { Select, Space, Table, Typography } from 'antd';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MESSAGE_CONTENT } from '../../contents/Constants';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError, notificationSuccess } from '../../utils/Notification';

const PaymentContent = () => {
  const paymentContentListing = useSelector(state => {
    return get(state, 'paymentContent.list.data', {
      count: 0,
      results: [],
      next: false
    });
  });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState([]);

  useEffect(() => {
    dispatch(actions.getListPaymentContent({ limit: 1000 }));
  }, []);

  useEffect(() => {
    setList(paymentContentListing.results);
  }, [paymentContentListing]);

  const columnProps = {
    align: 'left',
    ellipsis: true,
    width: 200
  };

  const columns = [
    {
      ...columnProps,
      fixed: 'left',
      title: 'Code',
      dataIndex: 'code',
      width: 100,
      render: value => <Typography>{value}</Typography>
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Ngày tạo',
      width: 90,
      dataIndex: 'created_at'
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Người tạo',
      width: 90,
      dataIndex: 'created_by',
      render: arr => <Typography>{arr[0]?.name}</Typography>
    },
    {
      title: 'Trạng thái',
      dataIndex: 'users',
      width: 50,
      render: users => (
        <Typography style={{ color: 'red' }}>
          {users?.[0] ? 'Đã dùng' : ''}
        </Typography>
      )
    }
  ];

  const handleCreatePaymentContent = () => {
    setLoading(true);
    dispatch(
      actions.createPaymentContent({
        data: { code },
        callback: ({ error }) => {
          setLoading(false);
          if (error) {
            return notificationError(MESSAGE_CONTENT.duplicatePaymentContent);
          }
          setCode([]);
          dispatch(actions.getListPaymentContent({ limit: 1000 }));
          return notificationSuccess(MESSAGE_CONTENT.createSuccess);
        }
      })
    );
  };

  const onSelect = data => {
    setCode(data);
  };

  return (
    <Spin spinning={loading}>
      <Row justify="space-between">
        <Space size="small">
          <Select
            mode="tags"
            value={code}
            style={{ minWidth: 300 }}
            onChange={onSelect}
          />
          <Button
            type="primary"
            disabled={(code?.length || 0) < 1}
            onClick={handleCreatePaymentContent}
          >
            Save
          </Button>
        </Space>
      </Row>
      <Table
        bordered
        size="small"
        dataSource={list}
        scroll={{ x: '100%' }}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          pageSize: 10,
          size: 'small'
        }}
      />
    </Spin>
  );
};

export default PaymentContent;
