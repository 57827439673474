import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getStore } from '../../App';
import { CS_VERIFY_SO, MESSAGE_CONTENT } from '../../contents/Constants';
import services from '../../services';
import { notificationError, notificationSuccess } from '../../utils/Notification';
import TYPE from '../actions/TYPE';
import { getListDepositForTransaction } from './commonSaga';

// function* listDealGiftCardTransaction({ params: payload }) {
//   try {
//     const cleanedParams = pickBy(payload, identity);
//     const { data } = yield call(
//       services.marketplace.transaction.listDealGiftCardTransaction,
//       cleanedParams
//     );
//     yield put({ type: TYPE.LIST_DEAL_GIFT_CARD_TRANSACTION_SUCCESS, data });
//   } catch (error) {
//     yield put({ type: TYPE.LIST_DEAL_GIFT_CARD_TRANSACTION_FAILED, error });
//   }
// }

function* listTransactions(action) {
  try {
    const cleanedParams = pickBy(action.params, identity);
    const { count, results } = yield call(
      services.getTransactionList,
      cleanedParams
    );
    yield put({
      type: TYPE.LIST_TRANSACTIONS_SUCCESS,
      data: results,
      count
    });

    // if (action.params.status === TRANSACTION_STATUS.buyngonConfirmed.key) {
    //   yield listDealGiftCardTransaction(action);
    // }
    yield getListDepositForTransaction();
  } catch (err) {
    yield put({
      type: TYPE.LIST_TRANSACTIONS_FAILED,
      message: err
    });
  }
}

function* acceptTransaction(action) {
  try {
    yield call(services.acceptTransaction, action);
    yield put({ type: TYPE.ACCEPT_TRANSACTION_SUCCESS });
    notificationSuccess('Xác nhận đơn hàng thành công');
  } catch (error) {
    notificationError(error.message);
    yield put({ type: TYPE.ACCEPT_TRANSACTION_FAILED, message: error });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* rejectTransaction(action) {
  try {
    const customerId = get(action, 'payload.user_id', null);
    const saleOrderId = get(action, 'payload._id', null);
    yield call(services.rejectTransaction, { customerId, saleOrderId });
    yield put({ type: TYPE.REJECT_TRANSACTION_SUCCESS });
    notificationSuccess('Từ chối đơn hàng thành công');
  } catch (error) {
    notificationError(error.message);
    yield put({ type: TYPE.REJECT_TRANSACTION_FAILED, message: error });
    // notificationError("Từ chối đơn hàng thất bại");
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* notificationSaleOrder(action) {
  try {
    const { saleOrderId } = action;
    yield put({
      type: TYPE.NOTIFICATION_TRANSACTION_SUCCESS,
      saleOrderId
    });
    notificationSuccess(MESSAGE_CONTENT.updateTransaction);
  } catch (err) {
    yield put({
      type: TYPE.NOTIFICATION_TRANSACTION_FAILED
    });
  }
}

function* getDraftSaleOrder(action) {
  try {
    const { userId } = action;
    const { data } = yield call(services.getDraftSaleOrder, userId);
    yield put({
      type: TYPE.GET_DRAFT_SALE_ORDER_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_DRAFT_SALE_ORDER_FAILED,
      message: error
    });
  }
}

function* addQuotationToSaleOrder(action) {
  try {
    const { userId, quotationList } = action;
    const { data } = yield call(services.addQuotationToSaleOrder, {
      user_id: userId,
      quotation_list: quotationList
    });
    yield put({
      type: TYPE.ADD_QUOTATION_TO_SALE_ORDER_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.ADD_QUOTATION_TO_SALE_ORDER_FAILED,
      message: error
    });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* editQuotation(action) {
  try {
    const { quotationId, userId, quotation } = action;
    const { data } = yield call(
      services.editQuotation,
      quotationId,
      userId,
      quotation
    );
    yield put({
      type: TYPE.EDIT_QUOTATION_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.EDIT_QUOTATION_FAILED,
      message: error
    });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* deleteQuotation(action) {
  try {
    const { quotationId, userId } = action;
    const { data } = yield call(services.deleteQuotation, quotationId, userId);
    yield put({
      type: TYPE.DELETE_QUOTATION_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.DELETE_QUOTATION_FAILED,
      message: error
    });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* createDeliveryAddress(action) {
  try {
    const { userId, address } = action;
    let newAddress = { ...address };

    if (address.type_addr === 'vietnam') {
      const { district, province, ward } = getStore().getState().location;
      const { province_id: provinceId, province_name: provinceName } = province.find(
        item => {
          return item.province_id === address.city;
        }
      );
      const { district_id: districtId, district_name: districtName } = district.find(
        item => {
          return item.district_id === address.district;
        }
      );
      const { ward_id: wardId, ward_name: wardName } = ward.find(item => {
        return item.ward_id === address.ward;
      });

      newAddress = {
        ...address,
        city: { id: provinceId, name: provinceName },
        district: { id: districtId, name: districtName },
        ward: { id: wardId, name: wardName }
      };
    }
    const { data } = yield call(services.createDeliveryAddress, userId, newAddress);
    yield put({
      type: TYPE.CREATE_DELIVERY_ADDRESS_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.CREATE_DELIVERY_ADDRESS_FAILED,
      message: error
    });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* selectedDeliveryAddress(action) {
  try {
    const { addressId, userId } = action;
    const { data } = yield call(services.selectedDeliveryAddress, addressId, userId);
    yield put({
      type: TYPE.SELECTED_DELIVERY_ADDRESS_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.SELECTED_DELIVERY_ADDRESS_FAILED,
      message: error
    });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* confirmSaleOrder(action) {
  try {
    const { userId, paymentCode } = action;
    yield call(services.confirmSaleOrder, {
      user_id: userId,
      payment_code: paymentCode
    });
    yield put({
      type: TYPE.CONFIRM_SALE_ORDER_SUCCESS
    });
  } catch (error) {
    yield put({
      type: TYPE.CONFIRM_SALE_ORDER_FAILED,
      message: error
    });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* uploadFileQuotation(action) {
  try {
    const { data } = yield call(services.importQuotation, action.file);
    yield put({
      type: TYPE.IMPORT_QUOTATION_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.IMPORT_QUOTATION_FAILED
    });
    notificationError('Vui lòng kiểm tra lại file import');
  }
}

function* csVerifyRetailSaleOrder(action) {
  try {
    yield call(services.csVerifyRetailSaleOrder, action.payload);
    yield put({
      type: TYPE.CS_VERIFY_RETAIL_SALE_ORDER_SUCCESS
    });
    if (action.payload.type === CS_VERIFY_SO.reject) {
      notificationSuccess('Đã huỷ đơn thành công');
    } else {
      notificationSuccess('Đã gửi yêu cầu đến kế toán');
    }
  } catch (err) {
    notificationError(err.message);
  }
}

function* reorderSaleOrder({ payload }) {
  const orderCode = payload.order_code;
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: orderCode });
    const { data } = yield call(services.reorderSaleOrder, payload);
    yield put({ type: TYPE.REORDER_SALE_ORDER_SUCCESS, data });
    notificationSuccess('Đã thêm sản phẩm vào giỏ hàng của khách');
  } catch (error) {
    yield put({ type: TYPE.REORDER_SALE_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: orderCode });
  }
}

function* handleEdit({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'handleEdit' });
    const { user_id: userId } = yield select(
      state => state.transaction.draftSaleOrder
    );
    const { _id: soId } = yield select(state => state.transaction.editQuotation);
    const { data } = yield call(
      services.handleEdit,
      { soId, userId },
      {
        link: payload.link,
        location: payload.location,
        image: payload.image,
        name: payload.name,
        color: payload.color,
        size: payload.size,
        quantity: payload.quantity,
        price: payload.price,
        sale_price: payload.salePrice,
        sale_price_type: payload.salePriceType,
        note: payload.note
      }
    );
    yield put({ type: TYPE.HANDLE_EDIT_SUCCESS, data });
    yield put({ type: TYPE.HANDLE_HIDE_QUOTATION });
  } catch (error) {
    yield put({ type: TYPE.HANDLE_EDIT_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'handleEdit' });
  }
}

function* updateWaitingSaleOrder({ payload }) {
  try {
    yield call(services.updateWaitingSaleOrder, payload);
    yield put({ type: TYPE.UPDATE_WAITING_SALE_ORDER_SUCCESS, payload });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_WAITING_SALE_ORDER_FAILED });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.LIST_TRANSACTIONS, listTransactions),
    takeLatest(TYPE.ACCEPT_TRANSACTION, acceptTransaction),
    takeLatest(TYPE.REJECT_TRANSACTION, rejectTransaction),
    takeLatest(TYPE.NOTIFICATION_TRANSACTION, notificationSaleOrder),
    takeLatest(TYPE.GET_DRAFT_SALE_ORDER, getDraftSaleOrder),
    takeLatest(TYPE.ADD_QUOTATION_TO_SALE_ORDER, addQuotationToSaleOrder),
    takeLatest(TYPE.EDIT_QUOTATION, editQuotation),
    takeLatest(TYPE.DELETE_QUOTATION, deleteQuotation),
    takeLatest(TYPE.CREATE_DELIVERY_ADDRESS, createDeliveryAddress),
    takeLatest(TYPE.SELECTED_DELIVERY_ADDRESS, selectedDeliveryAddress),
    takeLatest(TYPE.CONFIRM_SALE_ORDER, confirmSaleOrder),
    takeLatest(TYPE.IMPORT_QUOTATION, uploadFileQuotation),
    takeLatest(TYPE.CS_VERIFY_RETAIL_SALE_ORDER, csVerifyRetailSaleOrder),
    takeLatest(TYPE.REORDER_SALE_ORDER, reorderSaleOrder),
    takeLatest(TYPE.UPDATE_WAITING_SALE_ORDER, updateWaitingSaleOrder),
    takeLatest(TYPE.HANDLE_EDIT, handleEdit)
  ]);
}
