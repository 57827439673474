import { useDispatch } from 'react-redux';
import { CATEGORY_TYPE } from '../contents/Constants';
import actions from '../redux/actions';

export default function useCategory() {
  const dispatch = useDispatch();

  const handleEdit = list => {
    dispatch(actions.updateCategories(list));
  };

  const handleEditName = (type, name, groupIndex, categoryIndex) => {
    dispatch(
      actions.updateCategoriesName({ type, name }, groupIndex, categoryIndex)
    );
  };

  const handleDelete = (type, id, groupIndex, categoryIndex) => {
    const body = { type: CATEGORY_TYPE[type], id };
    dispatch(actions.deleteCategory(body, groupIndex, categoryIndex));
  };

  return [handleEdit, handleEditName, handleDelete];
}
