import React from 'react';
import Image from 'antd/lib/image';
import Space from 'antd/lib/space';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import './style.less';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import Images from '../../contents/Images';
import { MessageContent } from '../../contents/Labels';
import { uiConfigProps } from '../../firebase/firebaseMessage';
import { IS_AUTH, Routes } from '../../contents/Constants';

const Login = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: Routes.Transaction } };
  const isAuth = localStorage.getItem(IS_AUTH);

  if (isAuth) {
    return <Redirect to={from} />;
  }

  return (
    <Space direction="vertical" className="form-login">
      <Row gutter={[16]} align="middle" justify="center">
        <Col flex={1}>
          <Image preview={false} width={40} src={Images.logo} alt="logo" />
        </Col>
        <Col flex={4}>
          <Title level={4} className="form-login-title">
            {MessageContent.LoginTitle}
          </Title>
        </Col>
      </Row>
      <Row gutter={[0, 20]} align="middle" justify="center">
        <Col>
          <Text>{MessageContent.LoginWith}</Text>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col>
          <StyledFirebaseAuth {...uiConfigProps} />
        </Col>
      </Row>
    </Space>
  );
};

export default Login;
