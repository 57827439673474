import { get } from 'lodash';
import Table from 'antd/lib/table';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';

export default function Weight() {
  useEffect(() => {
    dispatch(actions.getShippingCategories());
  }, []);

  const shippingCategories = useSelector(state => get(state, 'shipping.list', []));
  const columnProps = {
    align: 'left',
    ellipsis: true,
    width: 200
  };

  const columns = [
    {
      ...columnProps,
      fixed: 'left',
      title: 'Id',
      dataIndex: 'id'
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Name',
      dataIndex: 'name'
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Code',
      dataIndex: 'code'
    },
    {
      ...columnProps,
      fixed: 'left',
      title: 'Vn',
      dataIndex: 'vn'
    },
    {
      ...columnProps,
      title: 'Min Weight',
      dataIndex: 'min_weight'
    }
  ];

  return (
    <Table
      bordered
      size="small"
      dataSource={shippingCategories}
      scroll={{ x: '100%' }}
      columns={columns}
      rowClassName="editable-row"
      pagination={{
        pageSize: 20,
        size: 'small'
      }}
    />
  );
}
