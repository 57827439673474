import { Button, Collapse, Form, Input, List } from 'antd';
import React, { useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import Modal from 'antd/lib/modal/Modal';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';
import { any, func, number, objectOf } from 'prop-types';
import useDraggable from '../../../hooks/useDraggable';
import Category from '../Category';
import useCategory from '../../../hooks/useCategory';
import { CATEGORY_TYPE } from '../../../contents/Constants';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import ArrowDownIcon from '../../icons/ArrowDownIcon';

import './styles.less';

import actions from '../../../redux/actions';

const { Panel } = Collapse;

export default function Group({ group, onShowModal, groupIndex, setEditIndex }) {
  const listGroup = useSelector(state => {
    return get(state, 'category.groups.result', []);
  });
  const { onDragChildEnd } = useDraggable(listGroup, actions.draggableCategories);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [, handleEditName, handleDelete] = useCategory();
  const [expand, setExpand] = useState(false);

  const handleCreateCategory = () => {
    onShowModal('category', get(group, '_id', ''));
  };

  const handleEditGroup = e => {
    form.setFieldsValue({ name: get(group, 'name', '') });
    setShowModal(true);
    e.stopPropagation();
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      // const newValue = { ...group, name: values.name };
      handleEditName('group', values.name, groupIndex);
      setEditIndex(prevState => {
        if (prevState.fromIndex < 0 && prevState.toIndex < 0) {
          return { fromIndex: groupIndex, toIndex: groupIndex };
        }
        return {
          fromIndex:
            prevState.fromIndex < groupIndex ? prevState.fromIndex : groupIndex,
          toIndex: prevState.toIndex > groupIndex ? prevState.toIndex : groupIndex
        };
      });
      setShowModal(false);
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleDragCategory = (fromIndex, toIndex) => {
    onDragChildEnd(fromIndex, toIndex, get(group, '_id', ''), 'categories');
    setEditIndex(prevState => {
      if (prevState.fromIndex < 0 && prevState.toIndex < 0) {
        return { fromIndex: groupIndex, toIndex: groupIndex };
      }
      return {
        fromIndex:
          prevState.fromIndex < groupIndex ? prevState.fromIndex : groupIndex,
        toIndex: prevState.toIndex > groupIndex ? prevState.toIndex : groupIndex
      };
    });
  };

  const handleDeleteGroup = e => {
    handleDelete(CATEGORY_TYPE.group, get(group, '_id', ''), groupIndex);
    e.stopPropagation();
  };

  const handleExpand = value => {
    setExpand(!isEmpty(value));
  };

  const getExtra = () => {
    return (
      <>
        <Button type="link" onClick={handleEditGroup}>
          Chỉnh sửa
        </Button>
        <Button type="link" onClick={handleDeleteGroup}>
          Xóa
        </Button>
        {expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </>
    );
  };

  return (
    <>
      <Collapse
        onChange={handleExpand}
        className="collapse-group"
        expandIconPosition="left"
      >
        <Panel
          showArrow={false}
          header={
            <div className={`header ${expand ? 'header-primary' : ''}`}>
              {get(group, 'name', '')}
            </div>
          }
          onClick={() => {
            return setExpand(true);
          }}
          extra={getExtra()}
        >
          <Button onClick={handleCreateCategory} block type="dashed">
            + Tạo category
          </Button>
          <ReactDragListView
            nodeSelector=".ant-list-item.category-item"
            onDragEnd={handleDragCategory}
          >
            <List
              dataSource={get(group, 'categories', '')}
              renderItem={(category, index) => {
                return (
                  <List.Item className="category-item">
                    <Category
                      setEditIndex={setEditIndex}
                      groupIndex={groupIndex}
                      categoryIndex={index}
                      category={category}
                    />
                  </List.Item>
                );
              }}
            />
          </ReactDragListView>
        </Panel>
      </Collapse>
      <Modal
        title="Chỉnh sửa group"
        visible={showModal}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Group.Form form={form} />
      </Modal>
    </>
  );
}

Group.propTypes = {
  group: objectOf(any),
  onShowModal: func,
  groupIndex: number,
  setEditIndex: func
};

Group.defaultProps = {
  group: {},
  onShowModal: () => {},
  groupIndex: 0,
  setEditIndex: () => {}
};

Group.Form = function GroupForm({ ...restProps }) {
  return (
    <Form {...restProps}>
      <Form.Item
        name="name"
        label="Tên Group"
        rules={[{ required: true, message: 'Vui lòng nhập tên của category' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
