import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ACCESS_KEY,
  DURATION,
  IS_AUTH,
  REFRESH_TOKEN,
  Routes,
  SHOW_BUTTON
} from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import history from '../../navigation';
import services from '../../services';
import { notificationError, notificationSuccess } from '../../utils/Notification';
import actions from '../actions';
import TYPE from '../actions/TYPE';

function* getProfile() {
  try {
    const data = yield call(services.getProfile);
    yield put({
      type: TYPE.PROFILE_OWNER_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.PROFILE_OWNER_FAILED,
      message: err
    });
    history.push(Routes.Login);
  }
}

function* login(action) {
  try {
    const {
      payload: {
        user: { uid }
      }
    } = action;
    const { data } = yield call(services.login, { uid });
    localStorage.setItem(ACCESS_KEY, get(data, 'authentication.idToken'));
    localStorage.setItem(REFRESH_TOKEN, get(data, 'authentication.refreshToken'));
    localStorage.setItem(IS_AUTH, 1);
    history.push(Routes.Dashboard);
    yield put({
      type: TYPE.LOGIN_SUCCESS,
      data
    });
    notificationSuccess(MessageContent.LoginSuccess);
  } catch (err) {
    notificationError(
      MessageContent.LoginFailed,
      MessageContent.ReloadAndLoginAgain,
      SHOW_BUTTON.SHOW,
      DURATION.NEVER_CLOSE
    );
  }
}

function* updateFcmToken(action) {
  try {
    const { fcmToken } = action;
    const data = yield call(services.updateFcmToken, fcmToken);
    yield put({
      type: TYPE.UPDATE_FCM_TOKEN_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.UPDATE_FCM_TOKEN_FAILED,
      message: err
    });
  }
}

function* handleCheckAuth() {
  yield put(actions.getProfile());
  // const token = localStorage.getItem(ACCESS_KEY);
  // const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  // if (!token) {
  //   return;
  // }
  // // Convert JWT to Object
  // const parseResult = parseJwt(token);

  // if (parseResult) {
  //   if (parseResult.exp < new Date().getTime() / 1000) {
  //     yield put(actions.refreshToken(refreshToken));
  //   } else {
  //     yield put({ type: TYPE.PROFILE_OWNER });
  //   }
  // } else {
  //   yield put(actions.refreshToken(refreshToken));
  // }
}

function* logout() {
  yield put({ type: TYPE.LOGOUT_SUCCESS });
  localStorage.removeItem(ACCESS_KEY);
  localStorage.setItem(IS_AUTH, '');
  history.push(Routes.Login);
  yield put({ type: TYPE.OFF_LOADING });
}

function* handleRefreshToken(action) {
  const { data } = yield call(services.refreshToken, action.token);
  setTimeout(() => {
    localStorage.setItem(ACCESS_KEY, data);
    window.location.reload(true);
  }, 1 * 1000);
  yield notificationSuccess(
    MessageContent.NotificationUpdateTimeTransaction,
    '',
    1000
  );
}

export default function* root() {
  yield all([
    takeLatest(TYPE.PROFILE_OWNER, getProfile),
    takeLatest(TYPE.LOGIN, login),
    takeLatest(TYPE.LOGOUT, logout),
    takeLatest(TYPE.UPDATE_FCM_TOKEN, updateFcmToken),
    takeLatest(TYPE.CHECK_AUTH, handleCheckAuth),
    takeLatest(TYPE.REFRESH_TOKEN, handleRefreshToken)
  ]);
}
