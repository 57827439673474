import get from 'lodash/get';

const convertAddress = address => {
  const city = get(address, 'city.name');
  const district = get(address, 'district.name');
  const ward = get(address, 'ward.name');
  const street = get(address, 'street');
  const typeAddr = get(address, 'type_addr');

  if (typeAddr === 'vietnam')
    return `${street}${ward && ', '}${ward}${district && ', '}${district}${
      city && ', '
    }${city}`;

  return `${street}`;
};

const formatCurrency = currency => {
  return currency.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const roundNumber = (number, scale) =>
  +`${Math.round(`${number}e+${scale}`)}e-${scale}`;

const convertVND = VND => {
  return `${formatCurrency(roundNumber(VND, 0))} đ`;
};

const convertDollar = price => `$${roundNumber(price, 2)}`;

export { convertAddress, convertVND, formatCurrency, convertDollar };
