import React from 'react';
import { objectOf } from 'prop-types';
import { Comment, List, Avatar, Alert } from 'antd';
import { CS_VERIFY_SO } from '../../../../../contents/Constants';

export default function Action({ saleOrder }) {
  const { is_cs_verify, cs_verify, payment_option, auto_confirm_so_tran } =
    saleOrder;

  const message = () => {
    if (cs_verify === CS_VERIFY_SO.confirm) {
      return 'Đơn có thể xác nhận';
    }
    if (!is_cs_verify && (payment_option || auto_confirm_so_tran?.success)) {
      return 'Chưa kiểm tra';
    }

    return 'Yêu cầu huỷ đơn';
  };

  const type = cs_verify === CS_VERIFY_SO.confirm ? 'success' : 'error';

  const data = [
    {
      author: saleOrder?.cs_verify_by,
      avatar: (
        <Avatar style={{ background: '#1D50FF' }}>
          {(saleOrder?.cs_verify_by || '').slice(0, 2)}
        </Avatar>
      ),
      content: <p>{saleOrder?.cs_note}</p>
    }
  ];
  return (
    <>
      <Alert message={message()} type={type} showIcon />
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <li>
            <Comment
              author={item.author}
              avatar={item.avatar}
              content={item.content}
              datetime={item.datetime}
            />
          </li>
        )}
      />
    </>
  );
}

Action.propTypes = {
  saleOrder: objectOf
};

Action.defaultProps = {
  saleOrder: {}
};
